import React, { Component } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment'
import "moment/locale/de";
import "moment/locale/fr";
import i18n from '../../locales/i18n';

import './DateTimePicker.css';

class DateTimePicker extends Component<{}> {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    onChange = (value) => {
        const { input } = this.props;
        input.onChange(value);
    }

    render(){
        const { input: { value } } = this.props;
        const { input, name, required, meta, disabled, ...other} = this.props;
        input.value = moment(input.value);

        return(
            <div className="DateTimePicker">
                <InputGroup>
                    <Datetime
                        name={name}
                        onChange={ this.onChange }
                        value={input.value}
                        locale={i18n.language}
                        inputProps={{disabled: disabled ?? false}}
                        {...other}
                        className='date-picker'
                    />
                    <InputGroupAddon addonType="append">
                        <InputGroupText>
                            <i className="fa fa-calendar" />
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        )
    }
}

export default DateTimePicker;
