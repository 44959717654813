import { combineReducers } from "redux";
import { reducer as form } from 'redux-form';

import worker from "./worker";
import auth from "./auth";
import users from "./users";
import screenings from "./screenings";
import legacyScreenings from "./legacyScreenings";
import programmations from "./programmations";
import places from "./places";
import events from "./events";
import tktInstances from "./tktInstances.js";
import distributors from "./distributors.js";
import activities from "./activities.js";
import tasks from "./tasks.js";
import articles from "./articles.js";
import articlecategories from "./articlecategories.js";
import salepoints from "./salepoints.js";
import resources from "./resources.js";
import cashregisters from "./cashregisters.js";
import logs from "./logs.js";
import messages from "./messages.js";
import sections from "./sections.js";
import tags from "./tags.js";
import pricinglists from "./pricinglists.js";
import tickettypes from "./tickettypes.js";
import layouts from "./layouts.js";
import emailLayouts from "./emailLayouts.js";
import userSettings from "./userSettings.js";
import search from "./search.js";
import post_categories from "./post_categories.js";
import channels from "./channels.js";
import posts from "./posts.js";
import customers from "./customers.js";
import stats from "./stats.js";
import settings from "./settings.js";
import tables from "./tables.js";
import sioBridge from "./sioBridge.js";
import collectors from "./collectors.js";
import efts from "./efts.js";
import printers from "./printers.js";
import tktusers from "./tktusers.js";
import tickets from "./tickets.js";
import ui from "./ui.js";
import fonts from "./fonts.js";
import mailer from "./mailer.js";

export default combineReducers({
    worker,
    auth,
    users,
    screenings,
    programmations,
    form,
    places,
    events,
    tktInstances,
    distributors,
    tasks,
    activities,
    articles,
    articlecategories,
    salepoints,
    resources,
    cashregisters,
    logs,
    messages,
    sections,
    tags,
    pricinglists,
    tickettypes,
    emailLayouts,
    layouts,
    userSettings,
    search,
    post_categories,
    channels,
    posts,
    customers,
    stats,
    settings,
    tables,
    sioBridge,
    collectors,
    efts,
    printers,
    tktusers,
    tickets,
    ui,
    fonts,
    mailer,
    legacyScreenings
});
