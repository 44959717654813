import React from "react";
import EmailLayout from '../EmailLayout';
import EmailLayoutWidget from './EmailLayoutWidget';
import { Screen, Utils } from '../../services';
import { createStyleString } from '@capsizecss/core';

/** Class representing a PDF layout custom text widget. */
class CustomTextWidget extends EmailLayoutWidget {

    constructor(properties) {
        super({
            ...(CustomTextWidget.getDefaultOptions()),
            ...properties,
        });

        this.type    = EmailLayout.TEXT;
        this.subType = EmailLayout.CUSTOM;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        const textProperties = {};
        Utils.langs().forEach(lang => { textProperties[lang] = "";  });

        return {
            options: {
                text: textProperties,
                style: {},
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            EmailLayout.OPTION_COLOR,
            EmailLayout.OPTION_TEXT,
            EmailLayout.OPTION_PADDING,
        ]);
    }

    /**
     * Return this widget preview text as string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreviewText(props) {
        const { inEdition, lang } = props;
        return this.options?.text[lang] || (inEdition ? '.....'  : '');
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     */
    getPreview(props) {
        return (
            <div style={{ color: '#000000' }} >
                <div className={this.id} dangerouslySetInnerHTML={{__html: this.getPreviewText(props) }} />
            </div>
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'commenting-o';
    }
}

export default CustomTextWidget;
