import React, { Component } from 'react';

import './FileUpload.css';

class FileUpload extends Component<{}> {
    constructor(props) {
        super(props)
        this.onFileChange = this.onFileChange.bind(this)
    }

    onChange(e) {
        const { input: { onChange } } = this.props
        onChange(e.target.files[0])
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    onFileChange = async (e) => {
        const { input } = this.props
        const targetFile = e.target.files[0]
        if (targetFile) {
            const val = await this.getBase64(targetFile)
            input.onChange(val)
        } else {
            input.onChange(null)
        }
    }


    render(){
        const { input: { name } } = this.props;
        return(
            <input
                className="form-control"
                name={name}
                type='file'
                accept='.jpg, .png, .jpeg'
                onChange={this.onFileChange}
            />
        )
    }
}

export default FileUpload;

