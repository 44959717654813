import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Route } from 'react-router';
import Dashboard from '../../../containers/Dashboard/Dashboard';
import Users from '../../../containers/Users/Users';
import Layouts from '../../../containers/Layouts/Layouts';
import EmailLayouts from '../../../containers/EmailLayouts/EmailLayouts';
import Screenings from '../../../containers/Screenings/Screenings';
import Programmations from '../../../containers/Programmations/Programmations';
import Places from '../../../containers/Places/Places';
import Pricinglists from '../../../containers/Pricinglists/Pricinglists';
import Tickettypes from '../../../containers/Tickettypes/Tickettypes';
import TktInstances from '../../../containers/TktInstances/TktInstances';
import Tasks from '../../../containers/Tasks/Tasks';
import Distributors from '../../../containers/Distributors/Distributors';
import Activities from '../../../containers/Activities/Activities';
import Events from '../../../containers/Events/Events';
import Articles from '../../../containers/Articles/Articles';
import Articlecategories from '../../../containers/Articlecategories/Articlecategories';
import Postcategories from '../../../containers/Postcategories/Postcategories';
import Channels from '../../../containers/Channels/Channels';
import Posts from '../../../containers/Posts/Posts';
import Salepoints from '../../../containers/Salepoints/Salepoints';
import Resources from '../../../containers/Resources/Resources';
import Cashregisters from '../../../containers/Cashregisters/Cashregisters';
import Messages from '../../../containers/Messages/Messages';
import Sections from '../../../containers/Sections/Sections';
import Customers from '../../../containers/Customers/Customers';
import Stats from '../../../containers/Stats/Stats';
import Settings from '../../../containers/Settings/Settings';
import Placeholder from '../../../containers/Placeholder/Placeholder';
import Dashboards from '../../../containers/Dashboards/Dashboards';
import Devices from '../../../containers/Devices/Devices';
import TicketackUsers from '../../../containers/TicketackUsers/TicketackUsers';
import OldScreenings from '../../../containers/OldScreenings/OldScreenings';
import { Forbidden, FlashInfo } from '../../../components';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { swUpdate, updateUi } from '../../../redux';

import './MainContent.css';

const MainContent = () => {
    const { collapsedSideBar, isMobile } = useSelector(state => state.ui, shallowEqual);
    const { user }                       = useSelector(state => state.auth);
    const { pathname, hash }             = useLocation();
    const dispatch                       = useDispatch();

    useEffect(() => {
        if (hash === '#changelog')
            dispatch(updateUi({showChangelog: true}));
    }, [hash]);

    return (
        <div className={`MainContent${!isMobile ? (collapsedSideBar ? " reducedMainContent" : " expandedMainContent") : ""}`}>
            <FlashInfo className=""/>
            {(user.canAccess(pathname)) ?
                <>
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/admin" component={Dashboards} />
                    <Route path="/analysis" component={Dashboards} />
                    <Route path="/operation" component={Dashboards} />
                    <Route path="/users" component={Users} />
                    <Route path="/screenings" component={Screenings} />
                    <Route path="/programmations" component={Programmations} />
                    <Route path="/places" component={Places} />
                    <Route path="/tickettypes" component={Tickettypes} />
                    <Route path="/layouts" component={Layouts} />
                    <Route path="/email_layouts" component={EmailLayouts} />
                    <Route path="/pricinglists" component={Pricinglists} />
                    <Route path="/tktInstances" component={TktInstances} />
                    <Route path="/distributors" component={Distributors} />
                    <Route path="/events" component={Events} />
                    <Route path="/articles" component={Articles} />
                    <Route path="/articlecategories" component={Articlecategories} />
                    <Route path="/post_categories" component={Postcategories} />
                    <Route path="/channels" component={Channels} />
                    <Route path="/posts" component={Posts} />
                    <Route path="/salepoints" component={Salepoints} />
                    <Route path="/activities" component={Activities} />
                    <Route path="/tasks" component={Tasks} />
                    <Route path="/resources" component={Resources} />
                    <Route path="/cashregisters" component={Cashregisters} />
                    <Route path="/messages" component={Messages} />
                    <Route path="/sections" component={Sections} />
                    <Route path="/customers" component={Customers} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/saleslist" component={Placeholder} />
                    <Route path="/bookingstatus" component={Placeholder} />
                    <Route path="/bookingsurvey" component={Placeholder} />
                    <Route path="/tickets" component={Placeholder} />
                    <Route path="/stats/movies" component={Stats} />
                    <Route path="/stats/prices" component={Stats} />
                    <Route path="/stats/places" component={Stats} />
                    <Route path="/stats/days" component={Stats} />
                    <Route path="/stats/hours" component={Stats} />
                    <Route path="/stats/box_office" component={Stats} />
                    <Route path="/devices" component={Devices} />
                    <Route path="/ticketack_users" component={TicketackUsers} />
                    <Route path="/oldscreenings" component={OldScreenings} />
                </>
                :
                <Forbidden />
            }
        </div>
    );
}

export default MainContent;
