import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Button, ButtonGroup, Card, CardBody, CardTitle, CardSubtitle, CardText, CardFooter } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';
import { Field, submit } from 'redux-form'
import { withTranslation } from 'react-i18next';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import { Utils } from '../../../../services';
import _ from 'lodash';

import './UserActivities.css';

class UserActivities extends Component<{}> {
    remove(i) {
        const { fields, activities, meta: { form }, dispatchSubmitForm } = this.props;

        fields.remove(i);
        activities.splice(i, 1);
        setTimeout((e) => {
            dispatchSubmitForm(form);
        }, 10);
    }

    render() {
        const { t, fields, activities, all_activities, onDelete } = this.props;
        const default_lang                                        = Utils.default_lang();

        const isLoading = false;
        const filtered_activities = _.differenceBy(all_activities, activities, 'id');
        return (
            <div className="UserActivities">
                <Row>
                    <Col>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Field component="select" className="form-control" type="text" name="new_activity_id" id="new_activity_id">
                                        <option key="0" value="">---</option>
                                        {
                                            filtered_activities && filtered_activities.map((a) => <option key={a.id} value={a.id}>{a.name[default_lang]}</option>)
                                        }
                                    </Field>
                                </Col>
                                <Col>
                                    <Button type="submit" color="info">
                                        {isLoading ?
                                            <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                            :
                                            <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
                <Row>
                    {activities && activities.map((activity, i) =>
                        <Col key={i} className="activity-wrapper" xs="12" sm="12" md="4">
                            <Card className="activity">
                                <CardTitle>
                                    <div className="text-center">
                                        <h5>{ activity.name[default_lang] }</h5>
                                    </div>
                                </CardTitle>
                                <CardFooter className="text-center">
                                    <ButtonGroup>
                                        <Button color="danger" size="sm" onClick={ () => { this.remove(i);  }}>
                                            <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                        </Button>
                                    </ButtonGroup>
                                </CardFooter>
                            </Card>
                        </Col>
                    )}
                </Row>
                <FormGroup row>
                    <Label for="bic" sm={3}>{t('activities.sms_notification')}</Label>
                    <Col sm={9}>
                        <Field required component="select" className="form-control" type="text" name="activity_notification_sms" id="activity_notification_sms">
                            {
                                Utils.notifications().map((n) => <option key={n} value={n}>{t("activities.notification_" + n)}</option>)
                            }
                        </Field>
                    </Col>
                </FormGroup>
                <FormGroup row>
                      <Label for="bic" sm={3}>{t('activities.email_notification')}</Label>
                      <Col sm={9}>
                          <Field required component="select" className="form-control" type="text" name="activity_notification_email" id="activity_notification_email">
                              {
                                  Utils.notifications().map((n) => <option key={n} value={n}>{t("activities.notification_" + n)}</option>)
                              }
                          </Field>
                      </Col>
                </FormGroup>
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchSubmitForm: (form) => submit(form)
}
const mapStateToProps = state => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(UserActivities))
