import React, { useEffect, useState } from 'react';
import Wysiwyg from '../Wysiwyg/Wysiwyg';
import { Utils } from '../../services';
import _ from 'lodash';

import './TranslatableWysiwyg.css';

const TranslatableWysiwyg = ({ defaultValue, placeholder, onChange, fullToolbar }) => {
    const langs                           = Utils.langs();
    const [selectedLang, setSelectedLang] = useState(langs[0]);
    const [inputText, setInputText]       = useState(defaultValue);

    const changeValue = (value) => {
        let temp = _.cloneDeep(inputText);
        temp[selectedLang] = value || '';
        setInputText(temp);
        onChange(temp)
    }

    if (!langs?.length)
        return null;

    return (
        <div className="TranslatableWysiwyg">

            <div className="lang-switchers">
                {langs.map(l => (
                    <a key={l} onClick={e => setSelectedLang(l)} className={`lang-switcher ${selectedLang === l ? "active" : ""}`}>
                        {l.toUpperCase()}
                    </a>
                ))}
            </div>

            {langs.map((l, i) => (
                <div key={l} className="translatable-field-wrapper" style={{ display: selectedLang === l ? 'initial' : 'none' }}>
                    <Wysiwyg defaultValue={inputText[l]} onChange={changeValue} fullToolbar />
                </div>
            ))}

        </div>
    );
}

export default TranslatableWysiwyg;
