import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import { Button, Nav, Navbar, NavbarBrand, Collapse, ButtonGroup, Label } from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logOut } from '../../../../redux/modules/auth';
import { showTooltips, hideTooltips, hideDarkTheme, showDarkTheme } from '../../../../redux/modules/userSettings'
import { Utils } from '../../../../services';
import i18n from '../../../../locales/i18n';
import _ from 'lodash';
import logo from '../../../../assets/images/Kronos.svg';
import './TopBar.css';
import { Ability } from '../../../../components';

const TopBar = ({ section, onSectionSelected, onCollapseNavClick }) => {
    const { user }          = useSelector(state => state.auth);
    const { KUserSettings } = useSelector(state => state.userSettings);
    const { t }             = useTranslation();
    const dispatch          = useDispatch();
    const current_lng       = i18n.language;

    if (!user)
        return null;

    const doLogOut = () => {
        dispatch(logOut());
    };

    const toggleTooltips = () => {
        if (KUserSettings.displayTooltips === true)
            dispatch(hideTooltips());
        else
            dispatch(showTooltips());
    };

    const toggleTheme = () => {
        if (KUserSettings.darkTheme === true)
            dispatch(hideDarkTheme());
        else
            dispatch(showDarkTheme());
    };

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const onSelectSection = section => {
        onSectionSelected && onSectionSelected(section);
    };

    const checkTopBarAbilities = (bar) => {
        return user.verifyAccessSections(bar);
    }

    return (
        <Navbar className="TopBar fixed-top flex-md-nowrap p-0 shadow" expand="md">
            <NavbarBrand href="/" className="navbar-brand">
                <img src={logo} className="logo" alt="logo" />
            </NavbarBrand>
            <Collapse navbar>
                <ButtonGroup className="topBarButtonGroup">
                    {Utils.appSections().filter((s => checkTopBarAbilities(s.id))).map(s => (
                        <Link
                            key={s.id}
                            to={`/${s.id}`}
                            className={`d-flex justify-content-center align-items-center topBarBtn btn ${section === s.id ? "btnActive" : "btnNotActive"}`}
                            role="button"
                            onClick={() => onSelectSection(s.id)}
                        >
                            <i className={`fa ${s.icon} block`}></i>&nbsp;
                            <span className='d-none d-md-block' >{t(`app.section_${s.id}`)}</span>
                        </Link>
                    ))}
                </ButtonGroup>
                <Nav className={`ml-auto settingsNav`} navbar>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            <i className="fa fa-globe"></i>&nbsp;{t(`languages.${current_lng}`)}
                        </DropdownToggle>
                        <DropdownMenu right>
                            {['en', 'fr', 'de'].map((lng, i) =>
                                <Fragment key={i}>
                                    <DropdownItem key={lng} className={current_lng === lng ? 'active' : ''} onClick={() => {changeLanguage(lng); onCollapseNavClick()}}>
                                        {t(`languages.${lng}`)}
                                    </DropdownItem>
                                </Fragment>
                            )}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            <i className="fa fa-user"></i>&nbsp;{user.firstname}
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={toggleTheme} toggle={false}>
                                <i className={`fa fa-${KUserSettings?.darkTheme ? "sun-o" : "moon-o"}`}></i>
                                <Label className="mb-0">&nbsp;
                                    {KUserSettings?.darkTheme ? t("settings.light_theme") : t("settings.dark_theme")}
                                </Label>
                            </DropdownItem>
                            <DropdownItem onClick={toggleTooltips} toggle={false}>
                                <i className="fa fa-question-circle"></i>&nbsp;
                                <Label className="mb-0">
                                    {KUserSettings?.displayTooltips ? t("settings.hide_help") : t("settings.show_help")}
                                </Label>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>
                                <NavLink to={`/users/show/${user.id}`} onClick={onCollapseNavClick} className="text-dark">
                                    <i className="fa fa-user"></i>&nbsp; {t('app.my_profil')}
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={doLogOut}>
                                <i className="fa fa-sign-out"></i>&nbsp; {t('app.logout')}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <Ability can="settings:any">
                        <Button color="outline" onClick={onCollapseNavClick} size="sm">
                            <NavLink to="/settings/edit/default" className={`text-${KUserSettings.darkTheme ? "light" : "dark"}`}>
                                <i className="fa fa-gear"></i>
                            </NavLink>
                        </Button>
                    </Ability>
                    <Ability can="settings:any">
                        <Button color="outline" onClick={onCollapseNavClick} size="sm">
                            <NavLink to="/devices/" className={`text-${KUserSettings.darkTheme ? "light" : "dark"}`}>
                                <i className="fa fa-cubes"></i>
                            </NavLink>
                        </Button>
                    </Ability>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default TopBar;
