import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import { loadUsers } from '../../../redux/modules/users';
import { loadActivities } from '../../../redux/modules/activities';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import _ from 'lodash';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'
import { Ability } from '../../../components';

moment.locale('fr');
momentLocalizer();

let TaskForm = ({ handleSubmit, pristine, submitting }) => {
    const [currentTaskActivityId, setCurrentTaskActivityId] = useState(null);
    const { task, isLoading }                               = useSelector(state => state.tasks);
    const { users }                                         = useSelector(state => state.users);
    const { activities }                                    = useSelector(state => state.activities);
    const dispatch                                          = useDispatch();
    const { t }                                             = useTranslation();
    const default_lang                                      = Utils.default_lang();

    const onChangeActivity = (e) => {
        setCurrentTaskActivityId(e.target.value);
    };

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(loadActivities());
    }, []);

    useEffect(() => {
        if (task && task.activity)
            setCurrentTaskActivityId(task.activity.id);
    }, [task])

    if (!task)
        return null;

    const is_assignable = (task.status != Utils.TASK_STATUS_ACCEPTED || task.status == Utils.TASK_STATUS_CLAIMED);
    let filtered_users = currentTaskActivityId ?
        _.sortBy(
            _.filter(users, u => _.filter(
                u.activities, a => a.id == currentTaskActivityId).length
            ), u => u.fullname
        ) :
        users;

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col sm={6}>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("tasks.informations")}</CardTitle>
                            <CardText tag="div">
                                <Ability can='tasks:assign'>
                                    <FormGroup row>
                                        <Label for="activity_id" sm={3}>{t('tasks.activity')}</Label>
                                        <Col sm={9}>
                                            <Field onChange={(e) => onChangeActivity(e)} component="select" className="form-control" type="text" name="activity_id" id="activity_id">
                                                <option key="0" value="">---</option>
                                                {
                                                    activities.map((a) => <option key={a.id} value={a.id}>{a.name[default_lang]}</option>)
                                                }
                                            </Field>
                                        </Col>
                                    </FormGroup>
                                </Ability>
                                <FormGroup row>
                                    <Label for="user_id" sm={3}>{t('tasks.user')}</Label>
                                    <Col sm={9}>
                                        {is_assignable &&
                                            <Field component="select" className="form-control" type="text" name="user_id" id="user_id">
                                                <option key="0" value="">---</option>
                                                {
                                                    filtered_users && filtered_users.map((u) => <option key={u.id} value={u.id}>{u.fullname}</option>)
                                                }
                                            </Field>
                                        }
                                        {!is_assignable &&
                                            <span>{task.user && task.user.fullname}</span>
                                        }
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="comment" sm={3}>{t('tasks.comment')}</Label>
                                    <Col sm={9}>
                                        <Field component="textarea" className="form-control" type="text" name="comment" id="comment" />
                                    </Col>
                                </FormGroup>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={6}>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-calendar"></i>&nbsp;{t("tasks.timing")}</CardTitle>
                            <CardText tag="div">
                                <FormGroup row>
                                    <Label for="start_at" sm={3}>{t("tasks.start_at")}</Label>
                                    <Col sm={9}>
                                        <Field
                                            component={DateTimePicker}
                                            type="text"
                                            name="start_at"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="stop_at" sm={3}>{t("tasks.stop_at")}</Label>
                                    <Col sm={9}>
                                        <Field
                                            component={DateTimePicker}
                                            type="text"
                                            name="stop_at"
                                        />
                                    </Col>
                                </FormGroup>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col className="text-center">
                    <br />
                    <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                        {isLoading ?
                            <span>
                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                            </span>
                            :
                            <span>
                                <i className="fa fa-save"></i>&nbsp;
                                {t("common.save")}
                            </span>
                        }
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

const mapDispatchToProps = {}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
TaskForm = reduxForm({
    form: 'taskForm' // a unique identifier for this form
})(TaskForm);

// You have to connect() to any reducers that you wish to connect to yourself
TaskForm = connect(
    state => ({
        initialValues: state.tasks.task, // pull initial values from tasks reducer
        tasks: state.tasks,
        users: state.users,
        activities: state.activities,
    }), mapDispatchToProps
)(TaskForm);

export default TaskForm;
