import React, { useState }  from "react";
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button, Card, CardBody, CardText, CardTitle } from "reactstrap";
import { PdfLayout } from '../../../models';

const PdfWidgetsBrowser = ({ addWidget }) => {
    const { t } = useTranslation();

    const [type, setType] = useState(PdfLayout.TEXT);

    return (
        <div className="wrapper-1 mr-1">
            <div className="">
                <CardText tag="div" className="mb-4">
                    <ButtonGroup>
                        <Button color={type === PdfLayout.TEXT ? 'primary' : 'dark'} onClick={() => setType(PdfLayout.TEXT)}>
                            <i className="fa fa-comment-o" />{' '}
                            { t('layouts.text_widgets') }
                        </Button>
                        <Button color={type === PdfLayout.IMAGE ? 'primary' : 'dark'} onClick={() => setType(PdfLayout.IMAGE)}>
                            <i className="fa fa-picture-o" />{' '}
                            { t('layouts.image_widgets') }
                        </Button>
                        <Button color={type === PdfLayout.SHAPE ? 'primary' : 'dark'} onClick={() => setType(PdfLayout.SHAPE)}>
                            <i className="fa fa-square-o" />{' '}
                            { t('layouts.shape_widgets') }
                        </Button>
                    </ButtonGroup>
                </CardText>
                { type === PdfLayout.TEXT && (
                <CardText tag="div">
                    { PdfLayout.getAvailableWidgets(PdfLayout.TEXT).map((widget, index) => (
                        <Button key={ index } className="widgetBtn" color="outline" onClick={() => addWidget(widget.getType(), widget.getSubType())}>
                            <i className={ `fa fa-${widget.getIcon()}` } />{' '}
                            {t(`layouts.${widget.getType()}_${widget.getSubType()}`)}
                        </Button>
                    ))}
                </CardText>
                )}
                { type === PdfLayout.IMAGE && (
                <CardText tag="div">
                    { PdfLayout.getAvailableWidgets(PdfLayout.IMAGE).map(widget => (
                        <Button className="widgetBtn" color="outline" onClick={() => addWidget(widget.getType(), widget.getSubType())}>
                            <i className={ `fa fa-${widget.getIcon()}` } />{' '}
                            {t(`layouts.${widget.getType()}_${widget.getSubType()}`)}
                        </Button>
                    ))}
                </CardText>
                )}
                { type === PdfLayout.SHAPE && (
                <CardText tag="div">
                    { PdfLayout.getAvailableWidgets(PdfLayout.SHAPE).map(widget => (
                        <Button className="widgetBtn" color="outline" onClick={() => addWidget(widget.getType(), widget.getSubType())}>
                            <i className={ `fa fa-${widget.getIcon()}` } />{' '}
                            {t(`layouts.${widget.getType()}_${widget.getSubType()}`)}
                        </Button>
                    ))}
                </CardText>
                )}
            </div>
        </div>
    );
}

export default PdfWidgetsBrowser;
