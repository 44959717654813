import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Screen, Utils } from '../../../../services';
import { ItemsList, LanguageSelector, SendEmailModal } from '../../../../components';
import { Form, Row, Col, Button, ButtonGroup, Card, CardBody, CardText, CardTitle, Input, Label } from "reactstrap";
import _ from "lodash";

import { Setting, EmailLayout } from '../../../../models';
import { EmailLayoutOptions } from './EmailLayoutOptions';
import { EmailWidgetOptions } from './EmailWidgetOptions';
import { EmailWidgetsBrowser } from './EmailWidgetsBrowser';
import { SortableList } from "./SortableList";
import { arrayMoveImmutable } from 'array-move';
import moment from 'moment';

import { loadEmailLayout, loadEmailLayouts, updateEmailLayout, createEmailLayout, duplicateEmailLayout, deleteEmailLayout } from '../../../../redux';
import { loadFonts, loadPricinglists, loadScreenings, loadProgrammations, loadPlaces, loadTickettypes } from "../../../../redux";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import './EmailLayouts.css';

let EmailLayouts = (props) => {
    const { handleSubmit, submitting } = props;

    const { layouts, layout, isLoadingOne } = useSelector(state => state.layouts);

    const { programmations }  = useSelector(state => state.programmations);
    const { pricinglists }    = useSelector(state => state.pricinglists);
    const { screenings }      = useSelector(state => state.screenings);
    const { places }          = useSelector(state => state.places);
    const { tickettypes }     = useSelector(state => state.tickettypes);
    const { setting }         = useSelector(state => state.settings);

    const dispatch     = useDispatch();
    const { t, i18n }  = useTranslation();
    const { isMobile } = useSelector(state => state.ui);

    const { layoutId } = useParams();

    const [widgets, setWidgets]                         = useState([]);
    const [inEdition, setInEdition]                     = useState(false);
    const [sendModalIsVisible, showSendModal]           = useState(false);

    const [currentWidget, setCurrentWidget]             = useState(undefined);
    const [currentOptionsGroup, setCurrentOptionsGroup] = useState('options');
    const [previewLanguage, setPreviewLanguage]         = useState(i18n.language);
    const [pdfSize, setEmailSize]                         = useState("Custom");

    const [displayPrice, setDisplayPrice]   = useState(false);
    const [programmation, setProgrammation] = useState(false);
    const [place, setPlace]                 = useState(false);
    const [tickettype, setTickettype]       = useState(false);
    const [pricinglist, setPricinglist]     = useState(false);
    const [priceSelected, setPriceSelected] = useState(false);
    const [oneTimePass, setOneTimePass]     = useState(false);

    // layout remove confirmation
    const [confirmationIsVisible, setConfirmationVisible] = useState(false);

    useEffect(() => {
        dispatch(loadEmailLayouts());
    }, []);

    useEffect(() => {
        dispatch(loadEmailLayout(layoutId));
    }, [dispatch, layoutId])

    useEffect(() => {
        if (layout) {
            setWidgets(layout.widgets);
        }
    }, [layout])

    useEffect(() => {
        if (!layout || !widgets)
            return;

        layout.widgets = [...widgets];
        //dispatch(updateEmailLayout(layout));
    }, [widgets]);

    useEffect(() => {
        dispatch(loadScreenings({
            start_at_gte: null,
            stop_at_lte: null,
            places_ids: []
        }));
        dispatch(loadPricinglists());
        dispatch(loadProgrammations());
        dispatch(loadPlaces());
        dispatch(loadTickettypes());
        dispatch(loadFonts());
    }, [])

    const addLayout = () => dispatch(createEmailLayout(EmailLayout.defaultLayout()));

    const duplicate = async () => {
        await dispatch(duplicateEmailLayout(layout));
        dispatch(loadEmailLayouts());
    }

    const removeLayout = async () => {
        // reset current layout
        await dispatch(deleteEmailLayout(layout?._id));
        setCurrentWidget(undefined);
        setConfirmationVisible(false);
        dispatch(loadEmailLayouts());
    }

    const addWidget = (type, subType) => {
        if (!layout)
            return;

        const widget = EmailLayout.createWidget({ type, subType });
        layout.widgets.push(widget);
        dispatch(updateEmailLayout(layout));
        if (widget.isEditable())
            setTimeout(() => setCurrentWidget(widget), 200);
    };

    const duplicateWidget = () => {
        if (!layout)
            return;

        const newWidget = _.cloneDeep(currentWidget);
        delete newWidget.id;

        newWidget.position = newWidget.position || {x: 0, y: 0, z: 0};
        newWidget.position.x += 1;
        newWidget.position.y += 1;
        layout.widgets.push(newWidget);
        dispatch(updateEmailLayout(layout));
        setTimeout(() => setCurrentWidget(newWidget), 200);
    };

    const deleteWidget = () => {
        if (!layout || !currentWidget)
            return;

        layout.widgets = layout.widgets.filter(w => w.id !== currentWidget.id);
        dispatch(updateEmailLayout(layout));
        setCurrentWidget(undefined);
    };

    const moveWidgetBackward = () => {
        if (!currentWidget)
            return;

        const newWidgets = [currentWidget].concat(
            widgets.filter(w => w.id != currentWidget.id)
        );
        setWidgets(newWidgets);
        setCurrentWidget(newWidgets[0]);
    };

    const moveWidgetForward = () => {
        if (!currentWidget)
            return;

        const newWidgets = widgets.filter(w => w.id != currentWidget.id).concat([
            currentWidget
        ]);
        setWidgets(newWidgets);
        setCurrentWidget(newWidgets[newWidgets.length - 1]);
    };

    const sortWidgets = (oldIndex, newIndex) => {
        const newWidgets = arrayMoveImmutable(widgets, oldIndex, newIndex);
        setWidgets(newWidgets);
    };

    const handleChangeFromDragAndResize = (widget, index) => {
        const newWidgets = widgets;
        newWidgets[index] = {
            ...newWidgets[index],
            position: widget.position,
            dimensions: widget.dimensions
        };
        setWidgets([...newWidgets]);
    };

    const handleNewOptions = (widget) => {
        const newWidgets = _.cloneDeep(widgets).map(w => {
            if (w.id === widget.id)
                return { ...widget };
            return w;
        });;
        setWidgets(newWidgets);
    };

    const selectImage = (event, index) => {
        const file = event.target.files[0];
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            const newWidgets = widgets;
            const widget = newWidgets[index];
            widget.options.rfc2397_data = fileReader.result;
            newWidgets[index] = widget;
            setWidgets([...newWidgets]);
        }
        fileReader.readAsDataURL(file)
    };

    const loadOverrideTickettypes = (id) => {
        const tickettype = id ? tickettypes.find(t => t._id === id) : false;
        setTickettype(tickettype);
        setPricinglist(tickettype.pricings);
        setPriceSelected(false);
        setDisplayPrice(true);
        if (id === "one-time-pass") {
            setOneTimePass(true);

        } else {
            setOneTimePass(false);
            setProgrammation(false);
            setPlace(false);
        }
    }
    const loadOverridePrice = (id) => {
        if (id.length > 0) {
            for (const key in pricinglist) {
                if (pricinglist[key].name[i18n.language] === id) {
                    setPriceSelected(pricinglist[key]);
                }
            }
        } else {
            setProgrammation(false);
            setPlace(false);
        }
    }

    const loadOverridePricingList = (ids) => {
        const [pricingListId, pricingId] = ids.split(":");

        const pricinglist = pricinglists.find((p) => p._id == pricingListId);
        if (!pricinglist)
            return;

        const price = pricinglist.pricings[pricingId];

        if (price) {
            setPriceSelected(price);
        } else {
            setProgrammation(false);
            setPlace(false);
        }
    }
    const loadOverrideEvents = (id) => {
        if (id.length > 0) {
            const prog  = id ? programmations.find(p => p.id === id) : false;
            const event = id ? screenings.find(s => s._id === id) : false;

            if (prog) {
                const place = id ? places.find(p => p._id === prog.place_id) : false;
                setProgrammation(prog);
                setPlace(place);
            }
            if (event) {
                const place = id ? places.find(p => p._id === event.cinema_hall._id) : false;
                setProgrammation(event);
                setPlace(place);
            }
        } else {
            setProgrammation(false);
            setPlace(false);
        }
    }

    const history = useHistory();
    const onSelectLayout = (_id) => {
        setCurrentWidget(undefined);

        if (_id === '__add')
            return addLayout();

        history.push(`/email_layouts/edit/${_id}`);
        dispatch(loadEmailLayout(_id));
    }

    const selectWidget = (widget, index) => {
        setCurrentWidget(undefined);
        // we let the options widget the time to reset
        setTimeout(() => {
            if (widget.isEditable())
                setCurrentWidget(widget);
        }, 0);
    }

    const items = _.uniqBy((layouts || []), '_id').filter(l => l.layout_type === 'email').map((l, index) => {
        return {
            key: `${l.name[i18n.language]}.${index}`,
            title: l.name[i18n.language],
            icon: 'th',
            active: l._id === layout?._id ? true : false,
            depth: 0,
            selection: l._id
        }
    });
    items.push({
        key: '__add',
        selection: '__add',
        title: t("layouts.add_new_layout"),
        icon: 'plus',
        isButton: true,
        depth: 0
    });

    const icons = {
        'text_owner_info'      : 'user',
        'text_ticket_info'     : 'tag',
        'text_tickettype_info' : 'tags',
        'text_screening_info'  : 'film',
        'text_customer_info'   : 'gear',
        'text_environment_info': 'globe',
        'text_custom'          : 'commenting-o',
        'text_link'            : 'link',
        'image_library'        : 'photo',
        'image_custom'         : 'photo',
        'image_owner_contact'  : 'user',
        'image_qrcode'         : 'qrcode',
        'shape_frame'          : 'square-o',
        'shape_line'           : 'arrows-h'
    }

    const widgetsList = React.useMemo(() => {
        return (widgets || []).slice().reverse().map((w, index) => {
            const key = (widgets || []).length - index - 1;
            return {
                key: index,
                title: t(`layouts.${w.type}_${w.subType}`),
                icon: icons[w.type + '_' + w.subType],
                active: w.id === currentWidget?.id,
                depth: 0,
                selection: EmailLayout.createWidget(w)
            }
        });
    }, [widgets, currentWidget]);

    const mainContainerWidth  = 21;
    const mainContainerHeight = 29.7;

    const createGrid = () => {
        const gridArray = [];

        for (let index = 0.5; index < mainContainerWidth; index += 0.5) {
            gridArray.push(<div key={`x-${index}`} style={{ left: `${Screen.cmToPx(index)}px`, y: 0, height: `${Screen.cmToPx(mainContainerHeight)}px`, width: '1px', backgroundColor: '#E5E5E5', position: 'absolute' }} />)
            if ((index * 10) % 10 === 0)
                gridArray.push(<div key={`xx-${index}`} style={{ left: `${Screen.cmToPx(index)}px`, y: 0, height: `${Screen.cmToPx(0.4)}px`, width: '1px', backgroundColor: '#858585', position: 'absolute' }} />)
            else
                gridArray.push(<div key={`xx-${index}`} style={{ left: `${Screen.cmToPx(index)}px`, y: 0, height: `${Screen.cmToPx(0.1)}px`, width: '1px', backgroundColor: '#858585', position: 'absolute' }} />)
        }

        for (let index = 0.5; index < mainContainerHeight; index += 0.5) {
            gridArray.push(<div key={`y-${index}`} style={{ x: 0, top: `${Screen.cmToPx(index)}px`, height: '1px', width: `${Screen.cmToPx(mainContainerWidth)}px`, backgroundColor: '#E5E5E5', position: 'absolute' }} />)
            if ((index * 10) % 10 === 0)
                gridArray.push(<div key={`yy-${index}`} style={{ x: 0, top: `${Screen.cmToPx(index)}px`, height: '1px', width: `${Screen.cmToPx(0.4)}px`, backgroundColor: '#858585', position: 'absolute' }} />)
            else
                gridArray.push(<div key={`yy-${index}`} style={{ x: 0, top: `${Screen.cmToPx(index)}px`, height: '1px', width: `${Screen.cmToPx(0.1)}px`, backgroundColor: '#858585', position: 'absolute' }} />)
        }

        return gridArray
    }


    return (
        <div className="EmailLayouts">
            <Card>
                <CardBody>
                        <CardText tag="div">
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col className="post_categories-browser" sm={2}>
                                        <div className="TypeSelector">
                                            <ItemsList
                                                title={t('layouts.layouts')}
                                                items={items}
                                                onSelect={onSelectLayout}
                                            />
                                        </div>
                                        {layout &&
                                            <>
                                                <Card className="h-auto mb-3">
                                                    <CardBody>
                                                        <CardTitle className="small">{t('settings.delivery.type.general_informations')}</CardTitle>
                                                        <CardText tag="div">
                                                            <EmailLayoutOptions />
                                                            {!confirmationIsVisible && (
                                                            <>
                                                                <Row>
                                                                    <Col>

                                                                        <ButtonGroup>
                                                                            <Button onClick={() => duplicate()} color='info' size="sm">
                                                                                <i className="fa fa-copy"></i> {t("common.duplicate")}
                                                                            </Button>
                                                                            <Button color="danger" onClick={() => setConfirmationVisible(true)}>
                                                                                <span>
                                                                                    <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                                                                </span>
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                                <Row>
                                                                    <Col>

                                                                        <ButtonGroup>
                                                                            <Button onClick={handleSubmit} type="submit" color="success" disabled={submitting || isLoadingOne}>
                                                                                {isLoadingOne ?
                                                                                    <span><i className="fa fa-spinner fa-spin"></i></span>
                                                                                    :
                                                                                    <span><i className="fa fa-save"></i>&nbsp; {t("common.save")} </span>
                                                                                }
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            )}
                                                            {confirmationIsVisible && (
                                                            <Row>
                                                                <Col>
                                                                    <b>{t('common.are_you_sure')}</b>&nbsp;
                                                                    <ButtonGroup>
                                                                        <Button onClick={() => setConfirmationVisible(false)} color='info' size="sm">
                                                                            <i className="fa fa-times"></i> {t("common.no")}
                                                                        </Button>
                                                                        <Button type="submit" color="danger" onClick={removeLayout}>
                                                                            <span>
                                                                                <i className="fa fa-trash"></i>&nbsp;{t("common.yes")}
                                                                            </span>
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </Col>
                                                            </Row>
                                                            )}
                                                        </CardText>
                                                    </CardBody>
                                                </Card>
                                            </>
                                        }
                                    </Col>
                                    <Col className="post_categories-browser" sm={10}>
                                        {layout &&
                                            <>
                                                <Row>
                                                    <Col sm={inEdition ? 8 : 12}>
                                                        <div className={"topButtonsWrapper " + (isMobile ? "w-100" : "")}>
                                                            <ButtonGroup className={isMobile ? "w-100" : ""}>
                                                                <Button onClick={() => setInEdition(true)} color={inEdition ? 'primary' : 'dark'} size="sm">
                                                                    <i className="fa fa-pencil"></i> {t("settings.pdf.edit_mode")}
                                                                </Button>
                                                                <Button onClick={() => setInEdition(false)} color={!inEdition ? 'primary' : 'dark'} size="sm">
                                                                    <i className="fa fa-eye"></i> {t("settings.pdf.preview_mode.title")}
                                                                </Button>
                                                            </ButtonGroup>

                                                            <LanguageSelector onChange={lang => setPreviewLanguage(lang)} />
                                                            <Button onClick={() => showSendModal(true)} color="primary" size="sm" className="ml-2">
                                                                <i className="fa fa-envelope"></i> {t("layouts.send_test_email")}
                                                            </Button>
                                                        </div>
                                                        {!inEdition &&
                                                            // Tickettypes list
                                                            <div className={"dataTestButtonsWrapper d-flex"}>
                                                                <Label className="mt-2">{t("settings.pdf.preview_mode.data_test")}</Label>
                                                                <Input type="select" className="w-25 ml-3" onChange={e => loadOverrideTickettypes(e.target.value)}>
                                                                    <option value="">{t("settings.pdf.preview_mode.choose_tickettypes_list")}</option>
                                                                    {tickettypes && tickettypes.map(field => (
                                                                        <option key={field._id} value={field._id}>
                                                                                {Utils.localized_or_fallback(field.name, previewLanguage)}
                                                                        </option>
                                                                    ))}
                                                                </Input>

                                                                {!oneTimePass && displayPrice && pricinglist &&
                                                                    // Pricinglist in tickettype
                                                                    <Input type="select" className="w-25 ml-3" onChange={e => loadOverridePrice(e.target.value)}>
                                                                        <option value="">{t("settings.pdf.preview_mode.choose_price")}</option>
                                                                        {Object.values(pricinglist).map((list, index) => (
                                                                            <option key={index} value={list.name[i18n.language]}>
                                                                                {Utils.localized_or_fallback(list.name, previewLanguage)}  - {list.price.CHF} {Object.keys(list.price)}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                }
                                                                {oneTimePass &&
                                                                <>
                                                                    {/* All Pricinglists */}
                                                                    <Input type="select" className="w-25 ml-3" onChange={e => loadOverridePricingList(e.target.value)}>
                                                                        <option value="">{t("settings.pdf.preview_mode.choose_price")}</option>
                                                                        {pricinglists.map(list => (
                                                                            <optgroup key={list._id} label={list.name}>
                                                                                {Object.keys(list.pricings).map((key, index) => (
                                                                                <option
                                                                                    key={Utils.localized_or_fallback(list.pricings[key].name, previewLanguage)}
                                                                                    value={list._id +':'+ key}
                                                                                >
                                                                                    {Utils.localized_or_fallback(list.pricings[key].name, previewLanguage)} - {list.pricings[key].price.CHF} {Object.keys(list.pricings[key].price)}
                                                                                </option>
                                                                                ))}
                                                                            </optgroup>
                                                                        ))}
                                                                    </Input>
                                                                    <Input type="select" className="w-auto ml-3" onChange={e => loadOverrideEvents(e.target.value)}>
                                                                        <option value="">{t("settings.pdf.preview_mode.choose_movies_list")}</option>
                                                                        {screenings && screenings.length > 0 && screenings.map(field => (
                                                                            <option key={field.films[0]._id} value={field._id}>
                                                                                {Utils.localized_or_fallback(field.title, previewLanguage) +' - '+ moment(field.start_at).format('LT l')}
                                                                            </option>
                                                                        ))}
                                                                        {programmations && programmations.map(field => (
                                                                            <option key={field.id} value={field.id}>
                                                                                {Utils.localized_or_fallback(field.title, previewLanguage) +' - '+ moment(field.start_at).format('LT l')}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                </>
                                                                }
                                                            </div>
                                                        }
                                                        <div className={"previewWrapper " + (isMobile ? "w-100" : "")}>
                                                            <div className="preview">
                                                                <SortableList
                                                                    widgets={widgets}
                                                                    currentWidget={currentWidget}
                                                                    inEdition={inEdition}
                                                                    lang={previewLanguage}
                                                                    setting={new Setting(setting)}
                                                                    onClick={(widget, index) => selectWidget(widget, index)}
                                                                    onSort={sortWidgets}
                                                                    onDelete={deleteWidget}
                                                                    programmation={programmation}
                                                                    place={place}
                                                                    tickettype={tickettype}
                                                                    pricing={priceSelected}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={4}>
                                                        {inEdition && widgets.length > 0 &&
                                                        <>
                                                            <CardTitle className="small">{t('layouts.list_widget')}</CardTitle>
                                                            <div className="widgetsLists">
                                                                    <ItemsList
                                                                    items={widgetsList}
                                                                    onSelect={(widget, index) => selectWidget(widget, widgets.length - 1 - index)}
                                                                />
                                                            </div>
                                                        </>
                                                        }
                                                        {inEdition && currentWidget && (
                                                        <>
                                                            <CardTitle className="small">
                                                                {t('layouts.edit_widget')} - {t(`layouts.${currentWidget.type}_${currentWidget.subType}`)}
                                                            </CardTitle>
                                                            <EmailWidgetOptions
                                                                widget={EmailLayout.createWidget(currentWidget)}
                                                                currentGroup={currentOptionsGroup}
                                                                onGroupChange={setCurrentOptionsGroup}
                                                                onSubmit={handleNewOptions}
                                                                onDuplicate={duplicateWidget}
                                                                onBackward={moveWidgetBackward}
                                                                onForward={moveWidgetForward}
                                                                onDelete={deleteWidget}
                                                                onClose={() => setCurrentWidget(undefined)}
                                                            />
                                                        </>
                                                        )}
                                                        {inEdition && !currentWidget && (
                                                        <>
                                                            <CardTitle className="small">
                                                                {t('layouts.add_widget')}
                                                            </CardTitle>
                                                            <EmailWidgetsBrowser addWidget={addWidget} />
                                                        </>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </CardText>
                </CardBody>
            </Card>
            <SendEmailModal
                isOpen={sendModalIsVisible}
                layout={layout}
                onClose={() => showSendModal(false)}
            />
        </div>
    );
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
EmailLayouts = reduxForm({
  form: 'layoutForm', // a unique identifier for this form
  enableReinitialize: true
})(EmailLayouts);

EmailLayouts = connect(state => ({ initialValues: state.layouts.layout, }), {})(EmailLayouts);

export default EmailLayouts;
