import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { loadUsers, loadPlaces, loadTickettypes, sendEmail } from '../../redux';
import { Utils } from '../../services';
import { Field } from 'redux-form';
import { FormGroup, Label, InputGroup, InputGroupText, Input, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';

import "./SendEmailModal.css"

const SendEmailModal = props => {
    const { layout, task, isOpen, onClose, className } = props;

    const { user }        = useSelector(state => state.auth);
    const { users }       = useSelector(state => state.users)
    const { places }      = useSelector(state => state.places)
    const { tickettypes } = useSelector(state => state.tickettypes)

    const { isSendingEmail, sendingEmailErrorMessage, result } = useSelector(state => state.mailer);
    const { t, i18n } = useTranslation();

    const [infos, setInfos] = useState({
        name: '',
        address: '',
        subject: '',
        lang: Utils.default_lang(),
        ticket_id: '',
        tickettype_id: '',
        screening_id: '',
        place_id: ''
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(loadPlaces());
        dispatch(loadTickettypes());
    }, [dispatch]);

    useEffect(() => {
        setInfos({
            ...infos,
            name: [user?.firstname, user?.lastname].filter(Boolean).join(' '),
            address: user?.email,
            subject: `[${t('layouts.test')}] ${layout?.name[i18n.language]}`
        });
    }, [user, layout]);

    const send = () => {
        const params = {
            layout_id: layout?._id,
            subject: infos.subject,
            lang: infos.lang,
            context: {}
        };
        if (infos.address) {
            params.to = { address: infos.address };
            if (infos.name)
                params.to.name = infos.name;
        }
        ['ticket_id', 'tickettype_id', 'screening_id', 'place_id'].forEach(key => {
            if (infos[key])
                params.context[key] = infos[key];
        });

        dispatch(sendEmail(params));
    };

    const langField = React.useMemo(() => {
            const options = Utils.langs().map(l => {
            return {
                key: l,
                text: l.toUpperCase(),
                value: l
            };
        });

        return <Dropdown
            className="form-control"
            options={ options }
            value={ infos.lang }
            onChange={ (e, data) => setInfos({...infos, lang: data.value}) }
            placeholder={ t('layouts.context_lang_placeholder') }
            search
            selection
            clearable
        />;
    }, [infos]);

    const tickettypeField = React.useMemo(() => {
        const options = (tickettypes || []).map(tickettype => {
            return {
                key: tickettype._id,
                text: Utils.localized_or_fallback(tickettype.name, i18n.language),
                value: tickettype._id
            };
        });

        return <Dropdown
            className="form-control"
            options={ options }
            value={ infos.tickettype_id }
            onChange={ (e, data) => setInfos({...infos, tickettype_id: data.value}) }
            placeholder={ t('layouts.context_tickettype_id_placeholder') }
            search
            selection
            clearable
        />;
    }, [infos, tickettypes]);

    const placeField = React.useMemo(() => {
        const options = (places || []).map(place => {
            return {
                key: place._id,
                text: [place.cinema, place.name].filter(Boolean).join(' - '),
                value: place._id
            };
        });

        return <Dropdown
            className="form-control"
            options={ options }
            value={ infos.place_id }
            onChange={ (e, data) => setInfos({...infos, place_id: data.value }) }
            placeholder={ t('layouts.context_place_id_placeholder') }
            search
            selection
            clearable
        />;
    }, [infos, tickettypes]);

    return (
        <div className="SendEmailModal">
            <Modal isOpen={isOpen} toggle={onClose} className={className} contentClassName="modalCustomClass">
                <ModalHeader toggle={onClose}>
                    <i className="fa fa-envelope"></i>&nbsp;
                    {t('layouts.send_test_email')}
                </ModalHeader>
                <ModalBody>
                    <FormGroup row>
                        <Label sm={3}>{t('layouts.subject')}</Label>
                        <Col sm={9}>
                            <InputGroup>
                                <InputGroupText className="prepend-addon">
                                    <i className="fa fa-comment-o" />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    value={infos.subject}
                                    onChange={e => setInfos({...infos, subject: e.target.value})}
                                    placeholder={t('layouts.subject_placeholder')}
                                />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>{t('layouts.lang')}</Label>
                        <Col sm={9}>
                            <InputGroup>
                                <InputGroupText className="prepend-addon">
                                    <i className="fa fa-globe" />
                                </InputGroupText>
                                { langField }
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <b>{t('layouts.recipient')}</b>
                    <FormGroup row>
                        <Label for="recipient.address" sm={3}>{t('layouts.recipient_address')}</Label>
                        <Col sm={9}>
                            <InputGroup>
                                <InputGroupText className="prepend-addon">
                                    <i className="fa fa-envelope" />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    value={infos.address}
                                    onChange={e => setInfos({...infos, address: e.target.value})}
                                    placeholder={t('layouts.address_placeholder')}
                                />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="recipient.name" sm={3}>{t('layouts.recipient_name')}</Label>
                        <Col sm={9}>
                            <InputGroup>
                                <InputGroupText className="prepend-addon">
                                    <i className="fa fa-user" />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    value={infos.name}
                                    onChange={e => setInfos({...infos, name: e.target.value})}
                                    placeholder={t('layouts.recipient_name_placeholder')}
                                />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    {(
                        layout?.hasShortcodeOfType('ticket', i18n.language) ||
                        layout?.hasShortcodeOfType('tickettype', i18n.language) ||
                        layout?.hasShortcodeOfType('screening', i18n.language) ||
                        layout?.hasShortcodeOfType('place', i18n.language)
                    ) && (
                        <b>{t('layouts.context')}</b>
                    )}
                    {layout?.hasShortcodeOfType('ticket', i18n.language) && (
                        <FormGroup row>
                            <Label for="context.ticket_id" sm={3}>{t('layouts.context_ticket_id')}</Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <InputGroupText className="prepend-addon">
                                        <i className="fa fa-tag" />
                                    </InputGroupText>
                                    <Input
                                        type="text"
                                        value={infos.ticket_id}
                                        onChange={e => setInfos({...infos, ticket_id: e.target.value})}
                                        placeholder={t('layouts.context_ticket_id_placeholder')}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    )}
                    {layout?.hasShortcodeOfType('tickettype', i18n.language) && (
                        <FormGroup row>
                            <Label for="context.tickettype_id" sm={3}>{t('layouts.context_tickettype_id')}</Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <InputGroupText className="prepend-addon">
                                        <i className="fa fa-tags" />
                                    </InputGroupText>
                                    { tickettypeField }
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    )}
                    {layout?.hasShortcodeOfType('screening', i18n.language) && (
                        <FormGroup row>
                            <Label for="context.screening_id" sm={3}>{t('layouts.context_screening_id')}</Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <InputGroupText className="prepend-addon">
                                        <i className="fa fa-television" />
                                    </InputGroupText>
                                    <Input
                                        type="text"
                                        value={infos.screening_id}
                                        onChange={e => setInfos({...infos, screening_id: e.target.value})}
                                        placeholder={t('layouts.context_screening_id_placeholder')}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    )}
                    {layout?.hasShortcodeOfType('place', i18n.language) && (
                        <FormGroup row>
                            <Label for="context.place_id" sm={3}>{t('layouts.context_place_id')}</Label>
                            <Col sm={9}>
                                <InputGroup>
                                    <InputGroupText className="prepend-addon">
                                        <i className="fa fa-th" />
                                    </InputGroupText>
                                    { placeField }
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    )}
                    {result?.success && (
                        <>
                            <h4 className="text-success">
                                {t('layouts.email_successfully_sent')}
                            </h4>
                            {result.preview && t('layouts.email_was_catched')}
                            {result.preview && (
                                <div>
                                    <a href={result.preview} target="_blank">
                                        {t('layouts.click_on_preview_link')}
                                    </a>
                                </div>
                            )}
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        disabled={isSendingEmail}
                        onClick={send}
                    >
                        <i className={`fa fa-${isSendingEmail ? 'spinner fa-spin' : 'save'}`}></i>&nbsp;
                        {t('common.send')}
                    </Button>&nbsp;
                    <Button color="secondary" onClick={onClose}>
                        <i className="fa fa-close"></i>&nbsp;
                        {t('common.cancel')}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default SendEmailModal;
