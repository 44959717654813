import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import UserForm from '../UserForm/UserForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadUser, updateUser, toggleUserStatus } from '../../../redux/modules/users';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const UserEdit = ({}) => {
    const dispatch            = useDispatch();
    const { t }               = useTranslation();
    const userId              = useParams('userId').userId;
    const authUser            = useSelector(state => state.auth.user)
    const {
        user,
        isLoading,
        templateApplied,
        hasLoadingError
    } = useSelector(state => state.users)

    useEffect(() => {
        dispatch(loadUser(userId));
    }, [])

    useEffect(() => {
        dispatch(loadUser(userId));
    }, [userId])

    if (!user || !authUser || hasLoadingError)
        return null;

    const submit = (values) => {
        dispatch(updateUser(values));
    }

    const title = authUser.id === user.id ?
        <span>{t('users.update_my_profile')}</span>
        :
        <span>
            {t('users.user')}
            <small> | {t('users.update_user', {firstname: user.firstname, lastname: user.lastname})}</small>
        </span>

    const actions = (
        <div>
            {user &&
                <ButtonGroup>
                    <Button tag={Link} to={ `/users/show/${ user.id }` } color="warning" size="sm">
                        <i className="fa fa-arrow-left"></i>&nbsp;
                        {t('common.back')}
                    </Button>
                    {user.is_active && authUser.can('disable', 'users') && authUser.id !== user.id &&
                        <Button disabled={isLoading} color='danger' size="sm" onClick={ () => { dispatch(toggleUserStatus(user)); }}>
                            <i className={isLoading ? 'fa fa-spinner fa-spin' : 'fa fa-pause'}></i>&nbsp;
                            {t('users.disable')}
                        </Button>
                    }
                    {!user.is_active && authUser.can('enable', 'users') && authUser.id !== user.id &&
                        <Button disabled={isLoading} color='success' size="sm" onClick={ () => { dispatch(toggleUserStatus(user)); }}>
                            <i className={isLoading ? 'fa fa-spinner fa-spin' : 'fa fa-play'}></i>&nbsp;
                            {t('users.enable')}
                        </Button>
                    }
                </ButtonGroup>
            }
        </div>
    );

    return (
        <div className="UserEdit">
            <PageTitle icon="users" title={ title } actions={ actions } />

            {user && authUser &&
                <UserForm
                    onSubmit={submit}
                    authUser={authUser}
                    user={user}
                    templateApplied={templateApplied}
                    isLoading={isLoading}
                />
            }
        </div>
    );
}

export default UserEdit;
