import React from "react";
import {SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { EmailLayout } from '../../../../../models';
import "./SortableList.css";

const DragHandle = SortableHandle(() => (
    <span className="drag-handle">
        <i className="fa fa-bars" />
    </span>
));

const SortableItem = SortableElement(({ value }) => (
    <li>
        <DragHandle />
        { value }
    </li>
));

const SortableItems = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
        );
});

const SortableList = props => {
    const { widgets, fonts, inEdition, currentWidget, onClick, onDelete, onSort } = props;

    const onSortEnd = ({oldIndex, newIndex}) => {
        onSort && onSort(oldIndex, newIndex);
    };

    const editableContent = (content) => {
        return (
            <div className="editable-content" >{ content }</div>
        );
    };

    const items = widgets.map((data, index) => {
        const widget = EmailLayout.createWidget(data);

        const widgetContent = (
            <div>
                { widget?.getPreview({
                    inEdition: props.inEdition,
                    lang: props.lang,
                    setting: props.setting,
                    fonts,
                    programmation: props.programmation,
                    place: props.place,
                    tickettype: props.tickettype,
                    pricing: props.pricing
                })}
            </div>
        );

        return (
            <div onClick={ () => { onClick(widget, index); }} tabIndex={ -1 } className={ `${currentWidget?.id === widget?.id ? 'active' : ''}` } style={{position: 'relative'}}>
                { props.inEdition ? editableContent(widgetContent) : widgetContent }
            </div>
        );
    });

    return (
        <div className="SortableList">
            <SortableItems items={ items } onSortEnd={ onSortEnd } useDragHandle />
        </div>
    );
};

export default SortableList;
