import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Utils } from "../../../services";
import i18n from "../../../locales/i18n";
import { Col, Row, Button } from "reactstrap";
import ReactTable from "react-table-6";
import { useDispatch, useSelector } from "react-redux";
import { resetStat } from "../../../redux";

const SingleMovieTable = ({ filters }) => {
    const dispatch  = useDispatch();
    const { t }     = useTranslation();
    const { stat }  = useSelector(state => state.stats);

    if (!stat)
        return null;

    //Weekdays equivalence (wednesday to thursday)
    let days = [["3", t("common.we")], ["4", t("common.th")], ["5", t("common.fr")], ["6", t("common.sa")], ["7", t("common.su")], ["1", t("common.mo")], ["2", t("common.tu")],]
    let dataTable1 = [];
    let temp = stat.days;
    //First table, for each weekdays
    Object.keys(temp).map(film => {
        for (let i = 0; i < 7; i++) {
            if (temp[film].stats[days[i][0]] && temp[film]?.stats[days[i][0]]?.nbScreenings > 0) {
                dataTable1.push({
                    day: days[i][1],
                    date: moment(temp[film].stats[days[i][0]].day.substr(0, 10)).format("L"),
                    screenings: temp[film].stats[days[i][0]].nbScreenings,
                    pay: temp[film].stats[days[i][0]].nbPaid + temp[film].stats[days[i][0]].nbPaidPass,
                    free: temp[film].stats[days[i][0]].nbFree + temp[film].stats[days[i][0]].nbFreePass,
                    revenue: temp[film].stats[days[i][0]].total
                })
            }
        }
    });

    //Second table, revenues maths and stats
    //false / true is for "bold or not" conditional rendering
    let dataTable2 = [];
    temp = stat.prices_total.stats;
    dataTable2.push({
        title: [t("statstypes.gross"), true],
        revenue: [temp.total_net1.toFixed(2), true]
    });
    dataTable2.push({
        title: [t("statstypes.tax"), false],
        revenue: ["0", false]
    });
    if (temp.vat) {
        dataTable2.push({
            title: [t("statstypes.exempt") + " 1", true],
            revenue: [temp.total_net1.toFixed(2), true]
        });
        Object.keys(temp.vat).map(vat => {
            if (vat)
                dataTable2.push({
                    title: [`${t('pricinglists.pricing.VAT')} ${vat}%`, false],
                    revenue: [temp.vat[vat].total.toFixed(2), false]
                });
        })
        dataTable2.push({
            title: [t("statstypes.exempt") + " 2", true],
            revenue: [temp.total_net2.toFixed(2), true]
        });
    }
    dataTable2.push({
        title: [t("statstypes.deduction") + " SUISA 1.48%", false],
        revenue: ["-" + (temp.total_net2 - temp.total_net3).toFixed(2), false]
    });
    dataTable2.push({
        title: [t("statstypes.exempt") + " 3", true],
        revenue: [temp.total_net3.toFixed(2), true]
    });

    //Last table, tickets, pricings
    let dataTable3 = [];
    temp = stat.prices;
    Object.keys(temp).map(key => {
        Object.keys(temp[key]).map(ticket => {
            Object.keys(temp[key][ticket]).map(num => {
                if (temp[key][ticket][num].stats.nbTotal > 0) {
                    dataTable3.push({
                        ticket: temp[key][ticket][num].name[i18n.language],
                        price: temp[key][ticket][num].value[Utils.currency()],
                        entries: temp[key][ticket][num].stats.nbTotal,
                        revenue: temp[key][ticket][num].stats.total.toFixed(2)
                    });
                };
            });
        });
    });

    //Headers for letter-like display (Reciever, sender, addresses etc.)
    let dataLetterHeader = [];
    temp = stat.days;
    Object.keys(temp).map(film => {
        dataLetterHeader.push({
            dist: temp[film]._id.distributor,
            suisa: temp[film]._id.suisa,
            filmName: temp[film]._id.title.original,
            week: temp[film]._id.week,
            version: temp[film]._id.version,
            cinema: temp[film]._id.cinema,
            city: temp[film]._id.city,
            hall: temp[film]._id.hall[0]
        })
    })

    const dataSingleMovie = [dataTable1, dataTable2, dataTable3];
    const headerSingleMovie = dataLetterHeader[0];
    const columnsSingleMovie = [
        [
            {   //Table 1
                Header: () => (
                    <div style={{ textAlign: "left" }}>{t("common.day")}</div>),
                filterable: false,
                accessor: "day",
                Footer: row => (<b>{t("statstypes.total")}</b>)
            },
            {
                Header: t("statstypes.date"),
                filterable: false,
                className: 'align-center-stats',
                accessor: "date",
            },
            {
                Header: t("statstypes.screenings"),
                Footer: row => (<b>{stat.days_total.nbScreenings}</b>),
                filterable: false,
                accessor: "screenings",
                className: 'align-center-stats',
                maxWidth: 210,
            },
            {
                Header: t("statstypes.chart.paid_entries"),
                Footer: row => (<b>{stat.days_total.nbPaid + stat.days_total.nbPaidPass}</b>),
                filterable: false,
                accessor: "pay",
                className: 'align-center-stats',
                maxWidth: 210,
            },
            {
                Header: t("statstypes.chart.free_entries"),
                Footer: row => (<b>{stat.days_total.nbFree + stat.days_total.nbFreePass}</b>),
                filterable: false,
                accessor: "free",
                className: 'align-center-stats',
                maxWidth: 210,
            },
            {
                Header: () => (<div>{t("statstypes.chart.income")}</div>),
                Footer: row => (<div><b>{stat.days_total.total.toFixed(2) + " " + Utils.currency()}</b></div>),
                filterable: false,
                accessor: "revenue",
                className: 'income',
                headerClassName: 'income',
                Cell: row => (
                    <div>{row.value.toFixed(2) + " " + Utils.currency()}</div>
                ),
                maxWidth: 210,
            },
        ],
        [
            {   //Table 2
                filterable: false,
                accessor: "title",
                Cell: row => (
                    row.value[1] ?
                        <div><b>{row.value[0]}</b></div> :
                        <div>{row.value[0]}</div>
                )
            },
            {
                filterable: false,
                accessor: "revenue",
                className: 'income',
                headerClassName: 'income',
                Cell: row => (
                    row.value[1] ?
                        <div><b>{row.value[0] + " " + Utils.currency()}</b></div> :
                        <div>{row.value[0] + " " + Utils.currency()}</div>
                )
            }
        ],
        [
            {   //Table 3
                Header: () => (
                    <div style={{ textAlign: "left" }}>{t("statstypes.ticket")}</div>),
                accessor: "ticket",
                filterable: false,
                minWidth: 220,
                Footer: row => (<b>{t("statstypes.total")}</b>)
            },
            {
                Header: t("statstypes.price"),
                accessor: "price",
                filterable: false,
                className: 'align-center-stats',
                Cell: row => (<div>{row.value.toFixed(2) + " " + Utils.currency()}</div>),
                maxWidth: 210,
            },
            {
                Header: t("statstypes.entries"),
                accessor: "entries",
                filterable: false,
                className: 'align-center-stats',
                maxWidth: 420,
                Footer: row => (<b>{stat.days_total.nbTotal}</b>)
            },
            {
                Header: () => (<div>{t("statstypes.chart.income")}</div>),
                className: 'income',
                headerClassName: 'income',
                accessor: "revenue",
                filterable: false,
                Cell: row => (<div>{row.value + " " + Utils.currency()}</div>),
                maxWidth: 210,
                Footer: row => (<div><b>{stat.days_total.total.toFixed(2) + " " + Utils.currency()}</b></div>),
            },
        ]
    ];

    return (
        <div id="printContainer">
            <Row>
                <Col className="noPrint text-right">
                    <Button color='success' onClick={() => window.print()}><i className='fa fa-print'>&nbsp;{t("statstypes.print")}</i></Button>&nbsp;
                    <Button color='danger' onClick={() => dispatch(resetStat())}><i className='fa fa-times'>&nbsp;{t("common.back")}</i></Button>
                </Col>
            </Row>
            <Row>
                <Col className="onlyPrint">
                    <label style={{ fontSize: "40px" }}><i className='fa fa-search-plus'></i>&nbsp;{t("statstypes.movie")}</label><br />
                    <label style={{ fontSize: "30px" }}>{headerSingleMovie.filmName}</label>
                </Col>
            </Row>

            <Row>
                <Col>
                </Col>
                <Col className="distributor">
                    <b>
                        {headerSingleMovie?.dist?.name}
                    </b><br />
                    {headerSingleMovie?.dist?.address && headerSingleMovie.dist.address}<br />
                    {(headerSingleMovie?.dist?.zip && headerSingleMovie?.dist?.city) &&
                        headerSingleMovie?.dist?.zip + " " + headerSingleMovie?.dist?.city}<br />
                    {headerSingleMovie?.dist?.country && headerSingleMovie?.dist?.country}
                </Col>
            </Row>

            <Row>
                <Col>
                    <b>
                        {headerSingleMovie.suisa && headerSingleMovie.suisa + " " + headerSingleMovie.filmName} <br />
                        {headerSingleMovie.version && headerSingleMovie.version + " "}
                        {headerSingleMovie.hall && headerSingleMovie.hall + " - "}
                        {headerSingleMovie.cinema && headerSingleMovie.cinema + " - "}
                        {headerSingleMovie.city && headerSingleMovie.city}<br />
                    </b>
                    {[<span key="spanWeek" suppressContentEditableWarning={true} contentEditable="true">{headerSingleMovie.week}</span>, ". Semaine du ", moment(filters.start_date).format("L"), " au ", moment(filters.end_date).format("L")]}
                </Col>
                <Col></Col>
            </Row><br />

            <Row className="printTable">
                <Col>
                    {(columnsSingleMovie && dataSingleMovie) &&
                        <ReactTable
                            className="printTable"
                            data={dataSingleMovie[0]}
                            columns={columnsSingleMovie[0]}
                            showPagination={false}
                            defaultPageSize={dataSingleMovie[0].length}
                        />
                    }
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col>
                    {(columnsSingleMovie && dataSingleMovie) &&
                        <ReactTable
                            TheadComponent={props => null}
                            className="singleMovieIncome"
                            data={dataSingleMovie[1]}
                            columns={columnsSingleMovie[1]}
                            showPagination={false}
                            defaultPageSize={dataSingleMovie[1].length}
                        />
                    }
                </Col>
            </Row>
            <Row className="printTable">
                <Col>
                    {(columnsSingleMovie && dataSingleMovie) &&
                        <ReactTable
                            data={dataSingleMovie[2]}
                            columns={columnsSingleMovie[2]}
                            showPagination={false}
                            defaultPageSize={dataSingleMovie[2].length}
                        />
                    }
                </Col>
            </Row>
        </div>
    )
}

export default SingleMovieTable;