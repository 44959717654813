// Redux Tasks module
// see https://github.com/erikras/ducks-modular-redux

import { Tasks } from '../../services';

const LOADING_TASKS                  = 'kronos/tasks/LOADING_TASKS';
const LOADING_TASKS_SUCCESS          = 'kronos/tasks/LOADING_TASKS_SUCCESS';
const LOADING_TASKS_FAILURE          = 'kronos/tasks/LOADING_TASKS_FAILURE';
const LOADING_TASK                   = 'kronos/tasks/LOADING_TASK';
const LOADING_TASK_SUCCESS           = 'kronos/tasks/LOADING_TASK_SUCCESS';
const LOADING_TASK_FAILURE           = 'kronos/tasks/LOADING_TASK_FAILURE';
const UPDATING_TASK                  = 'kronos/tasks/UPDATING_TASK';
const UPDATING_TASK_SUCCESS          = 'kronos/tasks/UPDATING_TASK_SUCCESS';
const UPDATING_TASK_FAILURE          = 'kronos/tasks/UPDATING_TASK_FAILURE';
const START_CREATING_TASK            = 'kronos/tasks/START_CREATING_TASK';
const CREATING_TASK                  = 'kronos/tasks/CREATING_TASK';
const CREATING_TASK_SUCCESS          = 'kronos/tasks/CREATING_TASK_SUCCESS';
const CREATING_TASK_FAILURE          = 'kronos/tasks/CREATING_TASK_FAILURE';
const DELETING_TASK                  = 'kronos/tasks/DELETING_TASK';
const DELETING_TASK_SUCCESS          = 'kronos/tasks/DELETING_TASK_SUCCESS';
const DELETING_TASK_FAILURE          = 'kronos/tasks/DELETING_TASK_FAILURE';
const ASSIGNING_TASK                 = 'kronos/tasks/ASSIGNING_TASK';
const ASSIGNING_TASK_SUCCESS         = 'kronos/tasks/ASSIGNING_TASK_SUCCESS';
const ASSIGNING_TASK_FAILURE         = 'kronos/tasks/ASSIGNING_TASK_FAILURE';
const CHANGING_TASK_STATUS           = 'kronos/tasks/CHANGING_TASK_STATUS';
const CHANGING_TASK_STATUS_SUCCESS   = 'kronos/tasks/CHANGING_TASK_STATUS_SUCCESS';
const CHANGING_TASK_STATUS_FAILURE   = 'kronos/tasks/CHANGING_TASK_STATUS_FAILURE';
const CLAIMING_TASK                  = 'kronos/tasks/CLAIMING_TASK';
const CLAIMING_TASK_SUCCESS          = 'kronos/tasks/CLAIMING_TASK_SUCCESS';
const CLAIMING_TASK_FAILURE          = 'kronos/tasks/CLAIMING_TASK_FAILURE';
const ACCEPTING_TASK                 = 'kronos/tasks/ACCEPTING_TASK';
const ACCEPTING_TASK_SUCCESS         = 'kronos/tasks/ACCEPTING_TASK_SUCCESS';
const ACCEPTING_TASK_FAILURE         = 'kronos/tasks/ACCEPTING_TASK_FAILURE';
const REFUSING_TASK                  = 'kronos/tasks/REFUSING_TASK';
const REFUSING_TASK_SUCCESS          = 'kronos/tasks/REFUSING_TASK_SUCCESS';
const REFUSING_TASK_FAILURE          = 'kronos/tasks/REFUSING_TASK_FAILURE';
const RENOUNCING_TASK                = 'kronos/tasks/RENOUNCING_TASK';
const RENOUNCING_TASK_SUCCESS        = 'kronos/tasks/RENOUNCING_TASK_SUCCESS';
const RENOUNCING_TASK_FAILURE        = 'kronos/tasks/RENOUNCING_TASK_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    taskSuccessfullyCreated: false,
    tasks: [],
    task: null
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_TASKS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TASKS_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                tasks: action.tasks
            };
        case LOADING_TASKS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_TASK:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TASK_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                task: action.task
            };
        case LOADING_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_TASK:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_TASK_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                task: action.task
            };
        case UPDATING_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_TASK:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                taskSuccessfullyCreated: false,
                task: {}
            };
        case CREATING_TASK:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_TASK_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                taskSuccessfullyCreated: true,
                task: action.task
            };
        case CREATING_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                taskSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_TASK:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_TASK_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                taskSuccessfullyDeleted: true,
                task: action.task
            };
        case DELETING_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                taskSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case ASSIGNING_TASK:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case ASSIGNING_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                task: action.task,
                tasks: state.tasks.map((t) => t.id == action.task.id ? action.task : t),
            };
        case ASSIGNING_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case CHANGING_TASK_STATUS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CHANGING_TASK_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                task: action.task,
                tasks: state.tasks.map((t) => t.id == action.task.id ? action.task : t),
            };
        case CHANGING_TASK_STATUS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case CLAIMING_TASK:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CLAIMING_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                task: action.task,
                tasks: state.tasks.map((t) => t.id == action.task.id ? action.task : t),
            };
        case CLAIMING_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case ACCEPTING_TASK:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case ACCEPTING_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                task: action.task,
                tasks: state.tasks.map((t) => t.id == action.task.id ? action.task : t),
            };
        case ACCEPTING_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case REFUSING_TASK:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case REFUSING_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                task: action.task,
                tasks: state.tasks.map((t) => t.id == action.task.id ? action.task : t),
            };
        case REFUSING_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case RENOUNCING_TASK:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case RENOUNCING_TASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                task: action.task,
                tasks: state.tasks.map((t) => t.id == action.task.id ? action.task : t),
            };
        case RENOUNCING_TASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingTasks() { return { type: LOADING_TASKS } }
function loadingTasksSuccess(tasks) { return { type: LOADING_TASKS_SUCCESS, tasks: tasks } }
function loadingTasksFailure(err) { return { type: LOADING_TASKS_FAILURE, error: err } }
export function loadTasks(params) {
    return (dispatch) => {
        dispatch(loadingTasks());
        Tasks.list(params)
            .then(data => {
                dispatch(loadingTasksSuccess(data.tasks));
            })
            .catch(err => {
                dispatch(loadingTasksFailure(err))
            });
    }
}

function loadingTask() { return { type: LOADING_TASK } }
function loadingTaskSuccess(task) { return { type: LOADING_TASK_SUCCESS, task: task } }
function loadingTaskFailure(err) { return { type: LOADING_TASK_FAILURE, error: err } }
export function loadTask(taskId) {
    return (dispatch) => {
        dispatch(loadingTask());
        Tasks.get(taskId)
            .then(data => {
                const task = data.task;
                dispatch(loadingTaskSuccess(task));
            })
            .catch(err => {
                dispatch(loadingTaskFailure(err))
            });
    }
}

function updatingTask() { return { type: UPDATING_TASK } }
function updatingTaskSuccess(task) { return { type: UPDATING_TASK_SUCCESS, task: task } }
function updatingTaskFailure(err) { return { type: UPDATING_TASK_FAILURE, error: err } }
export function updateTask(task) {
    return (dispatch) => {
        dispatch(updatingTask());
        Tasks.update(task)
            .then(data => {
                const task = data.task;
                dispatch(updatingTaskSuccess(task));
            })
            .catch(err => {
                dispatch(updatingTaskFailure(err))
            });
    }
}

function creatingTask() { return { type: CREATING_TASK } }
function creatingTaskSuccess(task) { return { type: CREATING_TASK_SUCCESS, task: task } }
function creatingTaskFailure(err) { return { type: CREATING_TASK_FAILURE, error: err } }
export function startCreateTask() { return { type: START_CREATING_TASK } }
export function createTask(task) {
    return (dispatch) => {
        dispatch(creatingTask());
        Tasks.create(task)
            .then(data => {
                const task = data.task;
                dispatch(creatingTaskSuccess(task));
                dispatch(loadingTaskSuccess(task));
            })
            .catch(err => {
                dispatch(creatingTaskFailure(err))
            });
    }
}

function deletingTask() { return { type: DELETING_TASK } }
function deletingTaskSuccess() { return { type: DELETING_TASK_SUCCESS } }
function deletingTaskFailure(err) { return { type: DELETING_TASK_FAILURE, error: err } }
export function deleteTask(taskId) {
    return (dispatch) => {
        dispatch(deletingTask());
        Tasks.delete(taskId)
            .then(data => {
                dispatch(deletingTaskSuccess());
            })
            .catch(err => {
                dispatch(deletingTaskFailure(err))
            });
    }
}

function assigningTask() { return { type: ASSIGNING_TASK } }
function assigningTaskSuccess(task) { return { type: ASSIGNING_TASK_SUCCESS, task: task } }
function assigningTaskFailure(err) { return { type: ASSIGNING_TASK_FAILURE, error: err } }
export function assignTask(task, user_id) {
    return (dispatch) => {
        dispatch(assigningTask());
        Tasks.assign(task, user_id)
            .then(data => {
                dispatch(assigningTaskSuccess(data.task));
            })
            .catch(err => {
                dispatch(assigningTaskFailure(err))
            });
    }
}

function changingTaskStatus() { return { type: CHANGING_TASK_STATUS } }
function changingTaskStatusSuccess(task) { return { type: CHANGING_TASK_STATUS_SUCCESS, task: task } }
function changingTaskStatusFailure(err) { return { type: CHANGING_TASK_STATUS_FAILURE, error: err } }
export function changeTaskStatus(task, status) {
    return (dispatch) => {
        dispatch(claimingTask());
        Tasks.change_status(task, status)
            .then(data => {
                dispatch(changingTaskStatusSuccess(data.task));
            })
            .catch(err => {
                dispatch(changingTaskStatusFailure(err))
            });
    }
}

function claimingTask() { return { type: CLAIMING_TASK } }
function claimingTaskSuccess(task) { return { type: CLAIMING_TASK_SUCCESS, task: task } }
function claimingTaskFailure(err) { return { type: CLAIMING_TASK_FAILURE, error: err } }
export function claimTask(task) {
    return (dispatch) => {
        dispatch(claimingTask());
        Tasks.claim(task)
            .then(data => {
                dispatch(claimingTaskSuccess(data.task));
            })
            .catch(err => {
                dispatch(claimingTaskFailure(err))
            });
    }
}

function acceptingTask() { return { type: ACCEPTING_TASK } }
function acceptingTaskSuccess(task) { return { type: ACCEPTING_TASK_SUCCESS, task: task } }
function acceptingTaskFailure(err) { return { type: ACCEPTING_TASK_FAILURE, error: err } }
export function acceptTask(task) {
    return (dispatch) => {
        dispatch(acceptingTask());
        Tasks.accept(task)
            .then(data => {
                dispatch(acceptingTaskSuccess(data.task));
            })
            .catch(err => {
                dispatch(acceptingTaskFailure(err))
            });
    }
}

function refusingTask() { return { type: REFUSING_TASK } }
function refusingTaskSuccess(task) { return { type: REFUSING_TASK_SUCCESS, task: task } }
function refusingTaskFailure(err) { return { type: REFUSING_TASK_FAILURE, error: err } }
export function refuseTask(task) {
    return (dispatch) => {
        dispatch(refusingTask());
        Tasks.refuse(task)
            .then(data => {
                const task = data.task;
                dispatch(refusingTaskSuccess(task));
            })
            .catch(err => {
                dispatch(refusingTaskFailure(err))
            });
    }
}

function renouncingTask() { return { type: RENOUNCING_TASK } }
function renouncingTaskSuccess(task) { return { type: RENOUNCING_TASK_SUCCESS, task: task } }
function renouncingTaskFailure(err) { return { type: RENOUNCING_TASK_FAILURE, error: err } }
export function renounceTask(task, msg) {
    return (dispatch) => {
        dispatch(renouncingTask());
        Tasks.renounce(task, msg)
            .then(data => {
                const task = data.task;
                dispatch(renouncingTaskSuccess(task));
            })
            .catch(err => {
                dispatch(renouncingTaskFailure(err))
            });
    }
}
