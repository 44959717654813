import React from 'react';
import { connect, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ModulesField from '../../../components/ModulesField/ModulesField';
import TktInstanceAbilitiesTemplateList from './TktInstanceAbilitiesTemplateList/TktInstanceAbilitiesTemplateList';
import TktInstanceAbilitiesTemplateCreate from './TktInstanceAbilitiesTemplateCreate/TktInstanceAbilitiesTemplateCreate';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

let TktInstanceForm = ({ handleSubmit, pristine, submitting, tktInstances, isCreation }) => {
    const { t } = useTranslation();
    const { isLoading } = tktInstances;
    const authUser = useSelector(state => state.auth.user)

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("tktInstances.informations")}</CardTitle>
                            <CardText tag="div">
                                {authUser.isSuperAdmin() &&
                                    <>
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("tktInstances.name")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className="form-control" type="text" name="name" id="name" autoComplete="name" placeholder={t("tktInstances.name_placeholder")} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="engine_url" sm={3}>{t("tktInstances.engine_url")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className="form-control" type="text" name="engine_url" id="engine_url" autoComplete="engine_url" placeholder={t("tktInstances.engine_url_placeholder")} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="solego_url" sm={3}>{t("tktInstances.solego_url")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className="form-control" type="text" name="solego_url" id="solego_url" autoComplete="solego_url" placeholder={t("tktInstances.solego_url_placeholder")} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="api_key" sm={3}>{t("tktInstances.api_key")}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className="form-control" type="text" name="api_key" id="api_key" autoComplete="api_key" placeholder={t("tktInstances.api_key_placeholder")} />
                                            </Col>
                                        </FormGroup>
                                    </>
                                }
                                {authUser.can('editOther', 'tktInstances') &&
                                    <FormGroup row>
                                        <Label for="email" sm={3}>{t("tktInstances.email")}</Label>
                                        <Col sm={9}>
                                            <Field component="input" className="form-control" type="email" name="email" id="email" autoComplete="email" placeholder={t("tktInstances.email_placeholder")} />
                                        </Col>
                                    </FormGroup>
                                }
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                {authUser.isSuperAdmin() &&
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-gears"></i>&nbsp;{t("tktInstances.options")}</CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Label for="modules" sm={3}>{t("tktInstances.modules")}</Label>
                                        <Col sm={9}>
                                            <ModulesField name="options.modules" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="sms_user" sm={3}>{t("tktInstances.sms_username")}</Label>
                                        <Col sm={9}>
                                            <Field component="input" className="form-control" type="text" name="options.sms_user" id="sms_user" placeholder={t("tktInstances.sms_username_placeholder")} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="sms_password" sm={3}>{t("tktInstances.sms_password")}</Label>
                                        <Col sm={9}>
                                            <Field component="input" className="form-control" type="password" name="options.sms_password" id="sms_password" placeholder={t("tktInstances.sms_password_placeholder")} autoComplete="new-password" />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
            {!isCreation && authUser.can('edit_templates', 'users') &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <i className="fa fa-graduation-hat"></i>&nbsp;{t("abilities.abilities_template")}
                                </CardTitle>
                                <CardText tag="div">
                                    <TktInstanceAbilitiesTemplateCreate instance={tktInstances.tktInstance} />
                                    <TktInstanceAbilitiesTemplateList instance={tktInstances.tktInstance} />
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
            <br />
            <Row>
                <Col className="text-center">
                    <br />
                    <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                        {isLoading ?
                            <span>
                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                            </span>
                            :
                            <span>
                                <i className="fa fa-save"></i>&nbsp;
                                {t("common.save")}
                            </span>
                        }
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
TktInstanceForm = reduxForm({
    form: 'tktInstanceForm', // a unique identifier for this form
    enableReinitialize: true,
})(TktInstanceForm)

// You have to connect() to any reducers that you wish to connect to yourself
TktInstanceForm = connect(
    state => ({
        initialValues: state.tktInstances.tktInstance, // pull initial values from tktInstances reducer
        tktInstances: state.tktInstances,
    })
)(TktInstanceForm)

export default TktInstanceForm;
