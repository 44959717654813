import RestClient from './RestClient';
import { TktInstance } from '../../models';

class TktInstances extends RestClient {
    constructor() {
        super({
            "model": TktInstance,
            "entryPoint": "tkt_instance",
            "resource": "tkt_instance",
            "resources": "tkt_instances",
            "sortBy": "name"
        });
    }
}

const TktInstancesApi = new TktInstances();

export default TktInstancesApi;
