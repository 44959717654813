// Redux Users module
// see https://github.com/erikras/ducks-modular-redux

import { Users, Utils } from '../../services/';
import { updateAuthUser } from './auth';

const LOADING_USERS                  = 'kronos/users/LOADING_USERS';
const LOADING_USERS_SUCCESS          = 'kronos/users/LOADING_USERS_SUCCESS';
const LOADING_USERS_FAILURE          = 'kronos/users/LOADING_USERS_FAILURE';
const LOADING_INACTIVE_USERS         = 'kronos/users/LOADING_INACTIVE_USERS';
const LOADING_INACTIVE_USERS_SUCCESS = 'kronos/users/LOADING_INACTIVE_USERS_SUCCESS';
const LOADING_INACTIVE_USERS_FAILURE = 'kronos/users/LOADING_INACTIVE_USERS_FAILURE';
const LOADING_USER                   = 'kronos/users/LOADING_USER';
const LOADING_USER_SUCCESS           = 'kronos/users/LOADING_USER_SUCCESS';
const LOADING_USER_FAILURE           = 'kronos/users/LOADING_USER_FAILURE';
const UPDATING_USER                  = 'kronos/users/UPDATING_USER';
const UPDATING_USER_SUCCESS          = 'kronos/users/UPDATING_USER_SUCCESS';
const UPDATING_USER_FAILURE          = 'kronos/users/UPDATING_USER_FAILURE';
const START_CREATING_USER            = 'kronos/users/START_CREATING_USER';
const CREATING_USER                  = 'kronos/users/CREATING_USER';
const CREATING_USER_SUCCESS          = 'kronos/users/CREATING_USER_SUCCESS';
const CREATING_USER_FAILURE          = 'kronos/users/CREATING_USER_FAILURE';
const DELETING_USER                  = 'kronos/users/DELETING_USER';
const DELETING_USER_SUCCESS          = 'kronos/users/DELETING_USER_SUCCESS';
const DELETING_USER_FAILURE          = 'kronos/users/DELETING_USER_FAILURE';
const DISABLING_USER                 = 'kronos/users/DISABLING_USER';
const DISABLING_USER_FAILURE         = 'kronos/users/DISABLING_USER_FAILURE';
const DISABLING_USER_SUCCESS         = 'kronos/users/DISABLING_USER_SUCCESS';
const ENABLING_USER                  = 'kronos/users/ENABLING_USER';
const ENABLING_USER_FAILURE          = 'kronos/users/ENABLING_USER_FAILURE';
const ENABLING_USER_SUCCESS          = 'kronos/users/ENABLING_USER_SUCCESS';
const APPLY_USER_ABILITIES_TEMPLATE  = 'kronos/users/APPLY_USER_ABILITIES_TEMPLATE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    userSuccessfullyCreated: false,
    userSuccessfullyUpdated: false,
    user: {},
    templateApplied: false
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_USERS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                users: action.users
            };
        case LOADING_USERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_INACTIVE_USERS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_INACTIVE_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                inactive_users: action.users
            };
        case LOADING_INACTIVE_USERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_USER_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                user: action.user
            };
        case LOADING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                userSuccessfullyUpdated: false,
            };
        case UPDATING_USER_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                user: action.user,
                userSuccessfullyUpdated: true,
                templateApplied: false
            };
        case UPDATING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
                userSuccessfullyUpdated: false
            };
        case START_CREATING_USER:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                userSuccessfullyCreated: false,
                user: {}
            };
        case CREATING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_USER_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                userSuccessfullyCreated: true,
                user: action.user
            };
        case CREATING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                userSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_USER_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                userSuccessfullyDeleted: true,
                user: action.user
            };
        case DELETING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                userSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case DISABLING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DISABLING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                user: action.user
            };
        case DISABLING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case ENABLING_USER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case ENABLING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                user: action.user
            };
        case ENABLING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case APPLY_USER_ABILITIES_TEMPLATE:
            return {
                ...state,
                user: {
                    ...state.user,
                    abilities : action.abilities
                },
                templateApplied: true
            };
        default:
            return state;
    }
};

// Actions
function loadingUsers() { return { type: LOADING_USERS } }
function loadingUsersSuccess(users) { return { type: LOADING_USERS_SUCCESS, users: users } }
function loadingUsersFailure(err) { return { type: LOADING_USERS_FAILURE, error: err } }
export function loadUsers() {
    return (dispatch) => {
        dispatch(loadingUsers());
        Users.list()
            .then(data => {
                const users = data.users;
                dispatch(loadingUsersSuccess(users));
            })
            .catch(err => {
                dispatch(loadingUsersFailure(err))
            });
    }
}

function loadingInactiveUsers() { return { type: LOADING_INACTIVE_USERS } }
function loadingInactiveUsersSuccess(users) { return { type: LOADING_INACTIVE_USERS_SUCCESS, users: users } }
function loadingInactiveUsersFailure(err) { return { type: LOADING_INACTIVE_USERS_FAILURE, error: err } }
export function loadInactiveUsers() {
    return (dispatch) => {
        dispatch(loadingInactiveUsers());
        Users.inactive()
            .then(data => {
                const users = data.users;
                dispatch(loadingInactiveUsersSuccess(users));
            })
            .catch(err => {
                dispatch(loadingInactiveUsersFailure(err))
            });
    }
}

function loadingUser() { return { type: LOADING_USER } }
function loadingUserSuccess(user) { return { type: LOADING_USER_SUCCESS, user: user } }
function loadingUserFailure(err) { return { type: LOADING_USER_FAILURE, error: err } }
export function loadUser(userId) {
    return (dispatch) => {
        dispatch(loadingUser());
        Users.get(userId)
            .then(data => {
                const user = data.user;
                dispatch(loadingUserSuccess(user));

            })
            .catch(err => {
                dispatch(loadingUserFailure(err))
            });
    }
}

function updatingUser() { return { type: UPDATING_USER } }
function updatingUserSuccess(user) { return { type: UPDATING_USER_SUCCESS, user: user } }
function updatingUserFailure(err) { return { type: UPDATING_USER_FAILURE, error: err } }
export function updateUser(user) {
    return (dispatch) => {
        dispatch(updatingUser());
        Users.update(user)
            .then(data => {
                const user = data.user;
                dispatch(updatingUserSuccess(user));
                dispatch(updateAuthUser(user));
            })
            .catch(err => {
                dispatch(updatingUserFailure(err))
            });
    }
}

export function applyUserAbilitiesTemplate(abilities) {
    return (dispatch) => {
        return dispatch({ type: APPLY_USER_ABILITIES_TEMPLATE, abilities });
    }
}


function creatingUser() { return { type: CREATING_USER } }
function creatingUserSuccess(user) { return { type: CREATING_USER_SUCCESS, user: user } }
function creatingUserFailure(err) { return { type: CREATING_USER_FAILURE, error: err } }
export function startCreateUser() { return { type: START_CREATING_USER } }
export function createUser(user) {
    return (dispatch) => {
        dispatch(creatingUser());
        Users.create(user)
            .then(data => {
                const user = data.user;
                dispatch(creatingUserSuccess(user));
                dispatch(loadingUserSuccess(user));
            })
            .catch(err => {
                dispatch(creatingUserFailure(err))
            });
    }
}

function deletingUser() { return { type: DELETING_USER } }
function deletingUserSuccess() { return { type: DELETING_USER_SUCCESS } }
function deletingUserFailure(err) { return { type: DELETING_USER_FAILURE, error: err } }
export function deleteUser(userId) {
    return (dispatch) => {
        dispatch(deletingUser());
        Users.hide(userId)
            .then(data => {
                dispatch(deletingUserSuccess());
            })
            .catch(err => {
                dispatch(deletingUserFailure(err))
            });
    }
}

function disablingUser() { return { type: DISABLING_USER } }
function disablingUserSuccess(user) { return { type: DISABLING_USER_SUCCESS, user: user } }
function disablingUserFailure(err) { return { type: DISABLING_USER_FAILURE, error: err } }
function enablingUser() { return { type: ENABLING_USER } }
function enablingUserSuccess(user) { return { type: ENABLING_USER_SUCCESS, user: user } }
function enablingUserFailure(err) { return { type: ENABLING_USER_FAILURE, error: err } }
export function toggleUserStatus(user) {
    return (dispatch) => {
        if (user.is_active) {
            dispatch(disablingUser());
            Users.disable(user.id)
                .then(data => {
                    dispatch(disablingUserSuccess(data.user));
                })
                .catch(err => {
                    dispatch(disablingUserFailure(err));
                });
        } else {
            dispatch(enablingUser());
            Users.enable(user.id)
                .then(data => {
                    dispatch(enablingUserSuccess(data.user));
                })
                .catch(err => {
                    dispatch(enablingUserFailure(err));
                });
        }
    }
}
