import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from "reactstrap";
import { Link } from "react-router-dom"
import './CreateButton.css';
import { useTranslation } from 'react-i18next';
import Ability from '../Ability';

const CreateButton = ({module, text, baseModule, customPath}) => {
    const { user } = useSelector(state => state.auth)
    const { t }    = useTranslation();

    if ((user?.can && user.can("create", baseModule ?? module)) || user?.abilities?.superadmin) {
        return(
            <Ability can={`${baseModule ?? module}:create`}>
                <Button className="CreateButton" tag={Link} to={customPath ?? `/${module}/new/`} color="success" title={t("common.add")} size="sm">
                    <i className="fa fa-plus"></i>&nbsp;{t(text)}
                </Button>
            </Ability>
        );
    } else return <></> 
}


export default CreateButton;
