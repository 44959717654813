import BaseModel from './BaseModel';
import _ from 'lodash';
/** Class representing a place. */
class Place extends BaseModel {

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = _.cloneDeep(this);

        if (prepared.map && !prepared.map.seats)
            prepared.map['seats'] = [];

        return prepared;
    }
}

export default Place;
