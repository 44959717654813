import React, { useEffect } from "react";
import PdfLayout from '../PdfLayout';
import CustomTextWidget from './CustomTextWidget';
import moment from 'moment';
import { Utils } from '../../services';
import { useTranslation } from 'react-i18next';

/**
 * Class representing a PDF layout widget showing
 * an information about the screening.
 */
class ScreeningInfoWidget extends CustomTextWidget {

    constructor(properties) {
        super({
            ...(ScreeningInfoWidget.getDefaultOptions()),
            ...properties,
        });

        this.type    = PdfLayout.TEXT;
        this.subType = PdfLayout.SCREENING_INFO;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 5, height: 0.75 },
            transform: { rotation: 0 },
            options: {
                field: 'title',
                style: {
                    color: '#000000',
                    fontFamily: "Helvetica",
                    fontSize: 14,
                    lineHeight: 1,
                    textAlign: 'left',
                    textDecoration: 'none',
                    backgroundColor: '#FFFFFF00',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        const handledOptions = super.getHandledOptions().concat([
            PdfLayout.OPTION_SCREENING_FIELD
        ]).filter(o => o !== PdfLayout.OPTION_TEXT);

        if (['start_at', 'stop_at', 'start_at_stop_at'].includes(this.options?.field))
            handledOptions.push(PdfLayout.OPTION_DATE_FORMAT);

        return handledOptions;
    }

    /**
     * Return this widget preview text as string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {Object} setting
     */
    getPreviewText(props) {
        const oneTimePass = "one-time-pass";
        const { t }       = useTranslation();
        const { programmation, place, tickettype, inEdition } = props;

        // Displays only screening information, if you are editing or simulating with a one-time pass.
        if (!inEdition && tickettype && oneTimePass != tickettype._id)
            return "";

        const momentFormat = this.options?.date_format ?? 'LT l';

        switch (this.options.field) {
            case 'title':
                return  Utils.localized_or_fallback(programmation.title, props.lang, t('models.screening.title'));
            case 'venue':
                return place?.cinema ?? t('models.screening.venue');
            case 'place':
                return place?.name ?? t('models.screening.place');
            case 'address':
                return place?.address ?? t('models.screening.address');
            case 'zip':
                return place?.zip ?? t('models.screening.zip');
            case 'city':
                return place?.city ?? t('models.screening.city');
            case 'state':
                return place?.state ?? t('models.screening.state');
            case 'country':
                return place?.country ?? t('models.screening.country');
            case 'start_at':
                // For NIFFF https://yoda.netoxygen.ch/projects/4b151b2c-6270-44e3-a92d-56c227854844/change/456500a0-8320-4239-a991-a751b001f883
                if (this.options?.date_format === "midnight_hack") {
                    return PdfLayout.midnightHack(programmation?.start_at, t("layouts.midnight"), t("layouts.midnight15"), t("layouts.midnight30"));
                } else {
                    return programmation?.start_at ? moment(programmation.start_at, 'DD.MM.YYYY HH:mm').format(momentFormat) : moment('01.12.1970 16:30', 'DD.MM.YYYY HH:mm').format(momentFormat);
                }
            case 'stop_at':
                return programmation?.stop_at ? moment(programmation.stop_at).format(momentFormat) : moment('01/12/1970 16:30').format(momentFormat);
            case 'start_at_stop_at':
                return [
                    programmation?.start_at ? moment(programmation.start_at).format(momentFormat) : moment('01/12/1970 16:30').format(momentFormat),
                    programmation?.stop_at ? moment(programmation.stop_at).format(momentFormat) : moment('1/12/1970 18:15').format(momentFormat)
                ].join(' - ');
            default:
                return '...';
        }
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'film';
    }
}

export default ScreeningInfoWidget;
