import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText, InputGroup, Input } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import { loadTktInstances } from '../../../redux/modules/tktInstances';
import { loadActivities } from '../../../redux/modules/activities';
import { applyUserAbilitiesTemplate } from '../../../redux/modules/users';
import UserActivities from './UserActivities/UserActivities';
import _ from 'lodash';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css'
import { PhoneField, EmailField, ToggleField } from '../../../components';

import './UserForm.css';
import TktInstanceAbilitiesTemplate from '../../TktInstances/TktInstanceForm/TktInstanceAbilitiesTemplate/TktInstanceAbilitiesTemplate';
moment.locale('fr');
momentLocalizer();

let UserForm = ({ handleSubmit, isCreation, authUser, isLoading, pristine, submitting, user, templateApplied }) => {
    const { t }                       = useTranslation();
    const dispatch                    = useDispatch();
    const { tktInstances }            = useSelector(state => state.tktInstances);
    const { activities }              = useSelector(state => state.activities);
    const [templateId, setTemplateId] = useState(0);

    useEffect(() => {
        if (authUser?.tkt_instance?.hasModule(Utils.MODULE_RESOURCES))
            dispatch(loadActivities());
        if (authUser?.abilities.superadmin)
            dispatch(loadTktInstances());
    }, [authUser])

    useEffect(() => {
        if (user?.abilities) {
            if (_.isEmpty(user?.abilities))
                dispatch(applyUserAbilitiesTemplate(Utils.generateAbilities()));
        }
    }, [user])

    const onApply = () => {
        if (templateId)
            dispatch(applyUserAbilitiesTemplate(authUser.tkt_instance.abilities_templates[templateId].abilities));
    }

    const onSelect = (e) => {
        setTemplateId(e.target.value);
    }

    const renderDateTimePicker = ({ input: { onChange, value }, showTime }) =>
        <DateTimePicker
            onChange={onChange}
            format="DD MMM YYYY"
            time={showTime}
            value={!value ? null : new Date(value)}
        />

    let optionsAbilities = authUser?.tkt_instance?.abilities_templates?.map((template, index) => {
        return [
            <option
                key={index}
                value={index}
            >
                {template.name}
            </option>
        ];
    });

    return (
        <Form className="form" onSubmit={handleSubmit}>
            <Row className="main">
                <Col sm={12} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <i className="fa fa-user-circle"></i>&nbsp;
                                {t('users.profile')}
                            </CardTitle>
                            <CardText tag="div">    
                                <FormGroup row>
                                    <Label for="firstname" sm={3}>{t('users.firstname')}</Label>
                                    <Col sm={9}>
                                        <Field required component="input" className="form-control" type="text" name="firstname" id="firstname" autoComplete="given-name" placeholder={t('users.firstname_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="lastname" sm={3}>{t('users.lastname')}</Label>
                                    <Col sm={9}>
                                        <Field required component="input" className="form-control" type="text" name="lastname" id="lastname" autoComplete="family-name" placeholder={t('users.lastname_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="new-password" sm={3}>{t('users.password')}</Label>
                                    <Col sm={9}>
                                        <Field required={isCreation} component="input" className="form-control" type="password" name="new-password" id="new-password" autoComplete="new-password" placeholder={t('users.password_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="new-password-confirmation" sm={3}>{t('users.password_confirmation')}</Label>
                                    <Col sm={9}>
                                        <Field required={isCreation} component="input" className="form-control" type="password" name="new-password-confirmation" id="new-password-confirmation" autoComplete="new-password" placeholder={t('users.password_placeholder')} />
                                    </Col>
                                </FormGroup>
                                {(!isCreation && authUser.isSuperAdmin()) &&
                                    <FormGroup row>
                                        <Label for="hidden" sm={3}>{t('users.hidden')}</Label>
                                        <Col sm={9}>
                                            <ToggleField name="hidden" />
                                        </Col>
                                    </FormGroup>
                                }
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <i className="fa fa-address-card"></i>&nbsp;
                                {t('users.address')}
                            </CardTitle>
                            <CardText tag="div">
                                <FormGroup row>
                                    <Label for="address" sm={3}>{t('users.address')}</Label>
                                    <Col sm={9}>
                                        <Field component="input" className="form-control" type="text" name="address" id="address" autoComplete="address-line1" placeholder={t('users.address_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="zip" sm={3}>{t('users.zip')}</Label>
                                    <Col sm={9}>
                                        <Field component="input" className="form-control" type="text" name="zip" id="zip" autoComplete="postal-code" placeholder={t('users.zip_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="city" sm={3}>{t('users.city')}</Label>
                                    <Col sm={9}>
                                        <Field component="input" className="form-control" type="text" name="city" id="city" autoComplete="address-level2" placeholder={t('users.city_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="country" sm={3}>{t('users.country')}</Label>
                                    <Col sm={9}>
                                        <Field component="input" className="form-control" type="text" name="country" id="country" autoComplete="country-name" placeholder={t('users.country_placeholder')} />
                                    </Col>
                                </FormGroup>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="main">
                <Col sm={12} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <i className="fa fa-envelope"></i>&nbsp;
                                {t('users.contact')}
                            </CardTitle>
                            <CardText tag="div">
                                <FormGroup row>
                                    <Label for="email" sm={3}>{t('users.email')}</Label>
                                    <Col sm={9}>
                                        <EmailField required name="email" placeholder={t('users.email_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="fix_phone" sm={3}>{t('users.fix_phone')}</Label>
                                    <Col sm={9}>
                                        <Field component={PhoneField} name="fix_phone" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="mobile_phone" sm={3}>{t('users.mobile_phone')}</Label>
                                    <Col sm={9}>
                                        <Field component={PhoneField} name="mobile_phone" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="pro_phone" sm={3}>{t('users.pro_phone')}</Label>
                                    <Col sm={9}>
                                        <Field component={PhoneField} name="pro_phone" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="fax" sm={3}>{t('users.fax')}</Label>
                                    <Col sm={9}>
                                        <Field component="input" className="form-control" type="text" name="fax" id="fax" autoComplete="tel-fax" placeholder={t('users.fax_placeholder')} />
                                    </Col>
                                </FormGroup>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} lg={6}>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <i className="fa fa-th-list"></i>&nbsp;
                                {t('users.other_informations')}
                            </CardTitle>
                            <CardText tag="div">
                                <FormGroup row>
                                    <Label for="birthday" sm={3}>{t('users.birthday')}</Label>
                                    <Col sm={9}>
                                        <Field showTime={false} component={renderDateTimePicker} className="form-control" type="text" name="birthday" id="birthday" autoComplete="" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="avs" sm={3}>{t('users.avs')}</Label>
                                    <Col sm={9}>
                                        <Field component="input" className="form-control" type="text" name="avs" id="avs" placeholder={t('users.avs_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="bank" sm={3}>{t('users.bank')}</Label>
                                    <Col sm={9}>
                                        <Field component="input" className="form-control" type="text" name="bank" id="bank" placeholder={t('users.bank_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="bic" sm={3}>{t('users.bic')}</Label>
                                    <Col sm={9}>
                                        <Field component="input" className="form-control" type="text" name="bic" id="bic" placeholder={t('users.bic_placeholder')} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="iban" sm={3}>{t('users.iban')}</Label>
                                    <Col sm={9}>
                                        <Field component="input" className="form-control" type="text" name="iban" id="iban" placeholder={t('users.iban_placeholder')} />
                                    </Col>
                                </FormGroup>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="main">
                {authUser?.tkt_instance?.hasModule(Utils.MODULE_RESOURCES) && !isCreation && activities && activities.length > 0 && authUser.can('pick_activities', 'users') &&
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-id-badge"></i>&nbsp;{t("users.activities")}</CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Col>
                                            <FieldArray name="activities" component={UserActivities} activities={user.activities} all_activities={activities} />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                }
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-ticket"></i>&nbsp;{t("tktInstances.tktInstance")}</CardTitle>
                            <CardText tag="div">
                                <FormGroup row>
                                    <Col>
                                        {authUser?.abilities?.superadmin &&
                                            <Field required component="select" className="form-control" type="text" name="tkt_instance_id" id="tkt_instance_id">
                                                {
                                                    tktInstances.map((i) => <option key={i.id} value={i.id}>{i.name}</option>)
                                                }
                                            </Field>
                                        }
                                        {!authUser?.abilities?.superadmin &&
                                            <>
                                                {!isCreation && user &&
                                                    user?.tkt_instance?.name
                                                }
                                                {isCreation &&
                                                    <div>
                                                        {authUser?.tkt_instance?.name}
                                                        <Field component="input" type="hidden" name="tkt_instance_id" />
                                                    </div>
                                                }
                                            </>
                                        }
                                    </Col>
                                </FormGroup>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {authUser.isSuperAdmin() ?
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Deprecated (Only for superadmin) old roles</CardTitle>
                                <CardText>
                                    <Field required component="select" className="form-control" type="text" name="role" id="role">
                                        {isCreation &&
                                            <option value="" disabled>{t("users.role_placeholder")}</option>}
                                        {
                                            ['user', 'admin', 'superAdmin'].map((r) => <option key={r} value={r}>{r}</option>)
                                        }
                                    </Field>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                :
                <Field component="input" type="hidden" name="role" value="user" />
            }
            {!isCreation && authUser.can('read_abilities', 'users') &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <i className="fa fa-graduation-cap"></i>&nbsp;
                                    Droits
                                </CardTitle>
                                <CardText tag="div">
                                    {optionsAbilities !== [] &&
                                        <Row>
                                            <Col sm={12} md={9}>
                                                {(authUser?.isSuperAdmin()) &&
                                                    <div className="d-flex align-items-center form-group">
                                                        <label className='d-block mr-3 mb-0 h5'>
                                                            <b>
                                                                {t("modules.module_super_admin")}
                                                            </b>
                                                        </label>
                                                        <Field
                                                            component="input"
                                                            type="checkbox"
                                                            className="form-control checkAbility"
                                                            name={`abilities.superadmin`}
                                                            format={v => { return !!v }}
                                                            style={{ height: '25px', width: '25px' }}
                                                        />
                                                    </div>
                                                }
                                            </Col>
                                            {authUser.can('edit_abilities', 'users') &&
                                                <Col sm={12} md={3}>
                                                    <InputGroup>
                                                        <Input
                                                            type="select"
                                                            onChange={e => onSelect(e)}
                                                            placeholder={t("abilities.chose_abilities_placeholder")}
                                                        >
                                                            <option key="none" value="">---</option>
                                                            {optionsAbilities}
                                                        </Input>
                                                        <Button
                                                            color="success"
                                                            onClick={() => onApply()}
                                                        >
                                                            {t("abilities.apply_template")}
                                                        </Button>
                                                    </InputGroup>
                                                </Col>
                                            }
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            {!_.isEmpty(user?.abilities) &&
                                                <TktInstanceAbilitiesTemplate editable={authUser.can('edit_abilities', 'users')} data={user?.abilities} />
                                            }
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
            <Row className="main">
                <Col className="text-center">
                    <ButtonGroup>
                        <Button tag={Link} to="/users" color="warning">
                            <span><i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}</span>
                        </Button>
                        <Button type="submit" color="success" disabled={!templateApplied && (pristine || submitting || isLoading)}>
                            <span>
                                <i className={`fa ${isLoading ? 'fa-spinner fa-spin' : 'fa fa-save'}`}></i>&nbsp;{t("common.save")}
                            </span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}

let mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
UserForm = reduxForm({
    form: 'userForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: true
})(UserForm)

// You have to connect() to any reducers that you wish to connect to yourself
UserForm = connect(
    state => ({
        initialValues: state.users.user,
        users: state.users,
        tktInstances: state.tktInstances,
        activities: state.activities,
    }), mapDispatchToProps
)(UserForm)

export default UserForm;
