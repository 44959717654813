import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, ButtonGroup, CardHeader, CardBody, Card } from 'reactstrap';
import { Field } from 'redux-form';
import { change } from 'redux-form';
import _ from 'lodash';
import "./TktInstanceAbilitiesTemplate.css"
import { Utils } from '../../../../services';

let TktInstanceAbilitiesTemplate = ({ editable, data, index, isEdit, onDelete }) => {
    const { t }       = useTranslation();
    const dispatch    = useDispatch();
    const formHandle  = isEdit ? `abilities_templates[${index}].abilities` : "abilities"
    const { user }    = useSelector(state => state.auth);
    let   modules     = null;
    const baseActions = ['create', 'readSelf', 'readOther', 'editSelf', 'editOther', 'deleteSelf', 'deleteOther'];

    if (user) {
        modules = Utils.getCurrentModules(user.tkt_instance.options.modules)
    }

    const allRow = (row, action) => {
        Object.keys(data[row]).map(k => {
            if (k === "module")
                return;
            dispatch(change(
                isEdit ? 'tktInstanceForm' : 'userForm',
                `${formHandle}[${row}].${k}`,
                action
            ));
        })
    }


    const CheckBoxRow = ({ actions, module, customTitle, rowButtons }) => {
        if (actions.length < 7)
            while(actions.length !== 7)
                actions.push('pad' + actions.length)
        return (
            <tr>
                <td className="moduleTitle">{t(customTitle ?? "app." + module)}</td>
                {
                    actions.map(a => 
                        <td className="checkBoxModule" key={a}>
                            {data[module][a] !== undefined ?
                                <CheckBoxModule
                                    rowKey={module}
                                    action={a}
                                />
                                :
                                <></>
                            }
                        </td>
                    )
                }
                <td className="allRowModule text-center">
                    {rowButtons === true && editable &&
                        <ButtonGroup>
                            <Button color="danger" onClick={() => allRow(module, false)} size="sm"><i className="fa fa-times"></i></Button>
                            <Button color="success" onClick={() => allRow(module, true)} size="sm"><i className="fa fa-check"></i></Button>
                        </ButtonGroup>
                    }
                </td>
            </tr>
        );
    }

    const CheckBoxModule = ({rowKey, action}) => {
        return (
            <Field
                component="input"
                type="checkbox"
                className="form-control checkAbility"
                name={`${formHandle}.${rowKey}.${action}`}
                format={v => { return !!v }}
                disabled={!editable}
            />
        );
    };

    let tables = [[], [], [], [], [], [], [], []]
    let tablesTitles = ["events", "publishing", "sale", "company", "tickets_clients", 'tasks', "settings", "stats"]
    let key = "";
    if (modules?.length > 0) {
        Object.keys(data).sort(u => u === 'users' ? 1 : -1).map((k, i) => {
            if (!modules.includes(k))
                return
            switch (k) {
                case "programmations":
                case "events":
                case "sections":
                case "distributors":
                case "places":
                case "screenings":
                    key = 0
                    break;
                case "posts":
                case "post_categories":
                case "channels":
                    key = 1;
                    break;
                case "articles":
                case "articlecategories":
                case "tickettypes":
                case "layouts":
                case "pricinglists":
                    key = 2;
                    break;
                case "salepoints":
                case "cashregisters":
                case "users":
                case "ticketack_users":
                case "activities":
                case "resources":
                    key = 3;
                    break;
                case "customers":
                case "messages":
                    key = 4;
                    break;
                case "tasks":
                    key = 5;
                    break;
                case "settings":
                case "tktInstances":
                    key = 6;
                    break;
                case "stats":
                    key = 7;
                    break;
            }
            tables[key].push(
                <CheckBoxRow
                    actions={baseActions}
                    module={k}
                    key={'boxes' + k}
                    rowButtons={(k === 'users' || k === 'tasks') ? false : true}
                />
            )
            //Special tasks boxes
            if (k === 'tasks') {
                tables[key].push(
                    <tr key={i + 'headers'}>
                        <th className="moduleTitle"></th>
                        <th className="text-center">
                            {t('tasks.abilities.assign')}
                        </th>
                        <th className="text-center">
                            {t('tasks.abilities.change_status')}
                        </th>
                        <th className="text-center">
                            {t('tasks.abilities.claim_for_others')}
                        </th>
                        <th className="text-center">
                            {t('tasks.abilities.renounce_for_others')}
                        </th>
                        <th className="text-center">
                            {t('tasks.abilities.claim')}
                        </th>
                        <th className="text-center">
                            {t('tasks.abilities.renounce')}
                        </th>
                        <th className="text-center">
                        </th>
                        <th className="text-center">
                        </th>
                    </tr>
                )
                tables[key].push(
                    <CheckBoxRow
                        module={k}
                        actions={['assign', 'change_status', 'claim_for_others', 'renounce_for_others', 'claim', 'renounce']}
                        key={k + '-special'}
                        customTitle={'tasks.abilities.tasks_abilities'}
                        rowButtons={true}
                    />
                )
            }
            //Special users boxes
            if (k === 'users') {
                tables[key].push(
                    <tr key={i + 'headers'}>
                        <th className="moduleTitle"></th>
                        <th className="text-center">
                            {t('users.abilities.pick_activities')}
                        </th>
                        <th className="text-center">
                            {t('users.abilities.enable')}
                        </th>
                        <th className="text-center">
                            {t('users.abilities.disable')}
                        </th>
                        <th className="text-center">
                            {t('users.abilities.read_abilities')}
                        </th>
                        <th className="text-center">
                            {t('users.abilities.edit_abilities')}
                        </th>
                        <th className="text-center">
                            {t('users.abilities.edit_templates')}
                        </th>
                        <th className="text-center">
                        </th>
                        <th className="text-center">
                        </th>
                    </tr>
                );
                tables[key].push(
                    <CheckBoxRow
                        module={k}
                        actions={['pick_activities', 'enable', 'disable', 'read_abilities', 'edit_abilities', 'edit_templates']}
                        key={k + '-special'}
                        customTitle={'users.abilities.users_abilities'}
                        rowButtons={true}
                    />
                )
            }
        })
    }
    let tableHtml = tables.map((tab, i) => {
        return [
            tab.length > 0 &&
            <Card key={i} className='border mb-3 cardAbilities'>
                <CardHeader>
                    <h3>{t("app." + tablesTitles[i])}</h3>
                </CardHeader>
                <CardBody>
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan={2}>
                                    {t("abilities.name")}
                                </th>
                                <th rowSpan={2} className="text-center">
                                    {t("abilities.create")}
                                </th>
                                <th className="text-center" colSpan={2}>
                                    {t("abilities.read")}
                                </th>
                                <th className="text-center" colSpan={2}>
                                    {t("abilities.edit")}
                                </th>
                                <th className="text-center" colSpan={2}>
                                    {t("abilities.delete")}
                                </th>
                                <th rowSpan={2} className="text-center">
                                    {t("abilities.all")}
                                </th>
                            </tr>
                            <tr>
                                <th className="text-center">
                                    {t("abilities.self")}
                                </th>
                                <th className="text-center">
                                    {t("abilities.other")}
                                </th>
                                <th className="text-center">
                                    {t("abilities.self")}
                                </th>
                                <th className="text-center">
                                    {t("abilities.other")}
                                </th>
                                <th className="text-center">
                                    {t("abilities.self")}
                                </th>
                                <th className="text-center">
                                    {t("abilities.other")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tab}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        ]
    })


    return (
        <div className="TktInstanceAbilitiesTemplate">
            {data && isEdit && user.can('edit_abilities', 'users') &&
                <Row className="pb-2">
                    <Col sm={12} md={3}>
                        <label>{t("abilities.name")} :</label>
                        <Field component="input" className="form-control" type="text" name={`abilities_templates[${index}].name`} />
                    </Col>
                    <Col sm={12} md={9}>
                        <Button className="float-right" color="danger" onClick={() => onDelete(index)} ><i className="fa fa-times"></i>&nbsp;{t("common.delete")}</Button>
                    </Col>
                </Row>
            }
            {tableHtml}
        </div>
    );
}

export default TktInstanceAbilitiesTemplate
