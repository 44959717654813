// Redux TktInstances module
// see https://github.com/erikras/ducks-modular-redux

import { TktInstances } from '../../services/';

const LOADING_TKT_INSTANCES                 = 'kronos/tktInstances/LOADING_TKT_INSTANCES';
const LOADING_TKT_INSTANCES_SUCCESS         = 'kronos/tktInstances/LOADING_TKT_INSTANCES_SUCCESS';
const LOADING_TKT_INSTANCES_FAILURE         = 'kronos/tktInstances/LOADING_TKT_INSTANCES_FAILURE';
const LOADING_TKT_INSTANCE                  = 'kronos/tktInstances/LOADING_TKT_INSTANCE';
const LOADING_TKT_INSTANCE_SUCCESS          = 'kronos/tktInstances/LOADING_TKT_INSTANCE_SUCCESS';
const LOADING_TKT_INSTANCE_FAILURE          = 'kronos/tktInstances/LOADING_TKT_INSTANCE_FAILURE';
const UPDATING_TKT_INSTANCE                 = 'kronos/tktInstances/UPDATING_TKT_INSTANCE';
const UPDATING_TKT_INSTANCE_SUCCESS         = 'kronos/tktInstances/UPDATING_TKT_INSTANCE_SUCCESS';
const UPDATING_TKT_INSTANCE_FAILURE         = 'kronos/tktInstances/UPDATING_TKT_INSTANCE_FAILURE';
const START_CREATING_TKT_INSTANCE           = 'kronos/tktInstances/START_CREATING_TKT_INSTANCE';
const CREATING_TKT_INSTANCE                 = 'kronos/tktInstances/CREATING_TKT_INSTANCE';
const CREATING_TKT_INSTANCE_SUCCESS         = 'kronos/tktInstances/CREATING_TKT_INSTANCE_SUCCESS';
const CREATING_TKT_INSTANCE_FAILURE         = 'kronos/tktInstances/CREATING_TKT_INSTANCE_FAILURE';
const DELETING_TKT_INSTANCE                 = 'kronos/tktInstances/DELETING_TKT_INSTANCE';
const DELETING_TKT_INSTANCE_SUCCESS         = 'kronos/tktInstances/DELETING_TKT_INSTANCE_SUCCESS';
const DELETING_TKT_INSTANCE_FAILURE         = 'kronos/tktInstances/DELETING_TKT_INSTANCE_FAILURE';
const APPLY_TKT_INSTANCE_ABILITIES_TEMPLATE = 'kronos/tktInstances/APPLY_TKT_INSTANCE_ABILITIES_TEMPLATE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    tktInstanceSuccessfullyCreated: false,
    tktInstances: [],
    tktInstance: null
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_TKT_INSTANCES:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TKT_INSTANCES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tktInstances: action.tktInstances
            };
        case LOADING_TKT_INSTANCES_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_TKT_INSTANCE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TKT_INSTANCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tktInstance: action.tktInstance
            };
        case LOADING_TKT_INSTANCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_TKT_INSTANCE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_TKT_INSTANCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tktInstance: action.tktInstance
            };
        case UPDATING_TKT_INSTANCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_TKT_INSTANCE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tktInstanceSuccessfullyCreated: false,
                tktInstance: {}
            };
        case CREATING_TKT_INSTANCE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_TKT_INSTANCE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                tktInstanceSuccessfullyCreated: true,
                tktInstance: action.tktInstance
            };
        case CREATING_TKT_INSTANCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                tktInstanceSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_TKT_INSTANCE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_TKT_INSTANCE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                tktInstanceSuccessfullyDeleted: true,
                tktInstance: action.tktInstance
            };
        case DELETING_TKT_INSTANCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                tktInstanceSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case APPLY_TKT_INSTANCE_ABILITIES_TEMPLATE:
            return {
                ...state,
                tktInstance: {
                    ...state.tktInstance,
                    abilities_templates : action.abilities_templates
                }
            };
        default:
            return state;
    }
};

// Actions
function loadingTktInstances() { return { type: LOADING_TKT_INSTANCES } }
function loadingTktInstancesSuccess(tktInstances) { return { type: LOADING_TKT_INSTANCES_SUCCESS, tktInstances: tktInstances } }
function loadingTktInstancesFailure(err) { return { type: LOADING_TKT_INSTANCES_FAILURE, error: err } }
export function loadTktInstances() {
    return (dispatch) => {
        dispatch(loadingTktInstances());
        TktInstances.list()
            .then(data => {
                const tktInstances = data.tkt_instances;
                dispatch(loadingTktInstancesSuccess(tktInstances));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingTktInstancesFailure(err))
            });
    }
}

function loadingTktInstance() { return { type: LOADING_TKT_INSTANCE } }
function loadingTktInstanceSuccess(tktInstance) { return { type: LOADING_TKT_INSTANCE_SUCCESS, tktInstance: tktInstance } }
function loadingTktInstanceFailure(err) { return { type: LOADING_TKT_INSTANCE_FAILURE, error: err } }
export function loadTktInstance(tktInstanceId) {
    return (dispatch) => {
        dispatch(loadingTktInstance());
        TktInstances.get(tktInstanceId)
            .then(data => {
                const tktInstance = data.tkt_instance;
                dispatch(loadingTktInstanceSuccess(tktInstance));
            })
            .catch(err => {
                dispatch(loadingTktInstanceFailure(err))
            });
    }
}

function updatingTktInstance() { return { type: UPDATING_TKT_INSTANCE } }
function updatingTktInstanceSuccess(tktInstance) { return { type: UPDATING_TKT_INSTANCE_SUCCESS, tktInstance: tktInstance } }
function updatingTktInstanceFailure(err) { return { type: UPDATING_TKT_INSTANCE_FAILURE, error: err } }
export function updateTktInstance(tktInstance) {
    return (dispatch) => {
        dispatch(updatingTktInstance());
        TktInstances.update(tktInstance)
            .then(data => {
                const tktInstance = data.tkt_instance;
                dispatch(updatingTktInstanceSuccess(tktInstance));
            })
            .catch(err => {
                dispatch(updatingTktInstanceFailure(err))
            });
    }
}

export function applyTktInstanceAbilitiesTemplate(abilities_templates) {
    return (dispatch) => {
        return dispatch({ type: APPLY_TKT_INSTANCE_ABILITIES_TEMPLATE, abilities_templates });
    }
}

function creatingTktInstance() { return { type: CREATING_TKT_INSTANCE } }
function creatingTktInstanceSuccess(tktInstance) { return { type: CREATING_TKT_INSTANCE_SUCCESS, tktInstance: tktInstance } }
function creatingTktInstanceFailure(err) { return { type: CREATING_TKT_INSTANCE_FAILURE, error: err } }
export function startCreateTktInstance() { return { type: START_CREATING_TKT_INSTANCE } }
export function createTktInstance(tktInstance) {
    return (dispatch) => {
        dispatch(creatingTktInstance());
        TktInstances.create(tktInstance)
            .then(data => {
                const tktInstance = data.tkt_instance;
                dispatch(creatingTktInstanceSuccess(tktInstance));
                dispatch(loadingTktInstanceSuccess(tktInstance));
            })
            .catch(err => {
                dispatch(creatingTktInstanceFailure(err))
            });
    }
}

function deletingTktInstance() { return { type: DELETING_TKT_INSTANCE } }
function deletingTktInstanceSuccess() { return { type: DELETING_TKT_INSTANCE_SUCCESS } }
function deletingTktInstanceFailure(err) { return { type: DELETING_TKT_INSTANCE_FAILURE, error: err } }
export function deleteTktInstance(tktInstanceId) {
    return (dispatch) => {
        dispatch(deletingTktInstance());
        TktInstances.delete(tktInstanceId)
            .then(data => {
                dispatch(deletingTktInstanceSuccess());
            })
            .catch(err => {
                dispatch(deletingTktInstanceFailure(err))
            });
    }
}
