import React, { useState }  from "react";
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form'
import {
    Row,
    Col,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText
 } from 'reactstrap';
import { PdfLayout } from '../../../models';
import TranslatableField from '../../TranslatableField/TranslatableField';

const PdfLayoutOptions = ({ index, pdfSize, setPdfPageSize, setMainContainerWidth, setMainContainerHeight }) => {
    const { t } = useTranslation();

    const [layoutType, setLayoutType]     = useState(PdfLayout.LAYOUT_TYPE_TICKET);

    return (
        <div className="PdfLayoutOptions">
            <Row>
                <Col>
                    <Label>{t('layouts.layout.name')}</Label>
                    <TranslatableField  data-for='layouts.name' required component="input" className="form-control" type="text" name={`pdf.layouts[${index}].name`} placeholder={t("tickettypes.name_placeholder")} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>{t('layouts.layout.type')}</Label>
                    <Field
                        component="select"
                        className="form-control w-100"
                        name={`pdf.layouts[${index}].type`}
                        value={ layoutType }
                        onChange= { e => setLayoutType(e.target.value)}
                        style={{width : '150px', height: '34px', marginRight: '10px'}}
                    >
                        { PdfLayout.types().map(type => {
                              return (
                                <option key={ type } value={ t(`layouts.type_${type}`).toLowerCase() }>
                                  { t(`layouts.type_${type}`) }
                                </option>
                              )
                        })}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>{t('layouts.layout.target')}</Label>
                    <Field
                        component="select"
                        className="form-control w-100"
                        name={`pdf.layouts[${index}].target`}
                        value={ layoutType }
                        onChange= { e => setLayoutType(e.target.value)}
                        style={{width : '150px', height: '34px', marginRight: '10px'}}
                    >
                        { PdfLayout.targets().map(target => {
                              return (
                                <option key={ target } value={ target }>
                                  { t(`layouts.target_${target}`) }
                                </option>
                              )
                        })}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>{t('layouts.layout.dimensions')}</Label>
                    <Field
                        data-for={`layouts.size`}
                        component="select"
                        className="form-control w-100"
                        name={`pdf.layouts[${index}].size`}
                        value={pdfSize}
                        onChange= { (event) => setPdfPageSize(event.target.value)}
                        style={{width : '150px', height: '34px', marginRight: '10px'}}
                    >
                        { PdfLayout.sizes().map((data) => {
                              return (
                                <option key={ data.name }>
                                  {data.name}
                                </option>
                              )
                        })}
                    </Field>
                </Col>
            </Row>
            {pdfSize && (pdfSize !== "Custom") && (
                <>
                    <Field
                        component="input"
                        min = { 0 }
                        type="hidden"
                        name={`pdf.layouts[${index}].dimensions.width`}
                        value={ PdfLayout.sizes(pdfSize).width }
                    />
                    <Field
                        component="input"
                        min = { 0 }
                        type="hidden"
                        name={`pdf.layouts[${index}].dimensions.height`}
                        value={ PdfLayout.sizes(pdfSize).height }
                    />
                </>
            )}
            {pdfSize == "Custom" && (
                <>
                    <Row>
                        <Col>
                            <InputGroup>
                                <Field
                                    component="input"
                                    type="number"
                                    className="form-control"
                                    name={`pdf.layouts[${index}].dimensions.width`}
                                    onChange={e => { setMainContainerWidth(e.target.value); }}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup>
                                <Field
                                    component="input"
                                    type="number"
                                    className="form-control"
                                    name={`pdf.layouts[${index}].dimensions.height`}
                                    onChange={e => { setMainContainerHeight(e.target.value); }}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}

export default PdfLayoutOptions;
