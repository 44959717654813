// Redux Articles module
// see https://github.com/erikras/ducks-modular-redux

const UPDATE_TABLE_STATE = 'kronos/tables/UPDATE_TABLE_STATE';

const initialTableState = (overridePageSize) => {
    return {
        page: 0,
        pageSize: overridePageSize ?? 10,
        sortState: [],
        filters: []
    }
};

const initialState = {
    activitiesTable              : initialTableState(),
    articlesCategoriesTable      : initialTableState(),
    articlesTable                : initialTableState(),
    cashregistersTable           : initialTableState(),
    channelsTable                : initialTableState(),
    customersTable               : initialTableState(),
    distributorsTable            : initialTableState(),
    eventsTable                  : initialTableState(),
    placesTable                  : initialTableState(),
    postCategoriesTable          : initialTableState(),
    postsTable                   : initialTableState(),
    pricingListsTable            : initialTableState(),
    programmationsTable          : initialTableState(),
    resourcesTable               : initialTableState(),
    salepointsTable              : initialTableState(),
    screeningsTable              : initialTableState(100),
    sectionsTable                : initialTableState(),
    tasksTable                   : initialTableState(),
    ticketTypesTable             : initialTableState(),
    tktInstancesTable            : initialTableState(),
    usersTable                   : initialTableState(),
    moviesStatsTable             : initialTableState(),
    pricesStatsTable             : initialTableState(),
    hourlyStatsTable             : initialTableState(),
    dailyStatsTable              : initialTableState(),
    boxStatsTable                : initialTableState(),
    roomsStatsTable              : initialTableState(),
    singleMovieDaysStatsTable    : initialTableState(),
    singleMovieRevenueStatsTable : initialTableState(),
    singleMovieTicketsStatsTable : initialTableState(),
    tktUsersTable                : initialTableState(),
    logTable                     : initialTableState(),
    screeningTickets             : initialTableState(),
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case UPDATE_TABLE_STATE:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    ...action.state
                }
            };
        default:
            return state;
    }
};

export function updateTableState(id, state) { return { type: UPDATE_TABLE_STATE, id, state } }