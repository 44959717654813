import React from 'react';
import { Field } from 'redux-form'
import BaseModel from './BaseModel';
import { Utils } from '../services';
import {
    ColorPickerField,
    SettingsImagesField,
    PaymentMethodsField,
    SalepointsField,
    ArticlesField,
    CategoriesField,
    PaymentMethodsForm,
    IntegrationsForm,
    DeliveryTypesForm,
    PostCategoriesField,
    TickettypesField,
    TranslatableField,
    UserDataField,
    EnvsField,
    DonationModesField,
    SettingsMobileField,
    RegistrationCallbackModesField,
    TwintMethodField,
    ShowCalculatorField,
    LocalesField,
    ExtraCurrenciesField,
    StringBoolean,
    PdfLayouts,
    PhoneField,
    BookingPolicyField,
    ToggleField,
    EmailField,
    CustomerTypeField
} from '../components';
import _ from 'lodash';

const form = "settingForm"

/** Class representing the Ticketack settings. */
class Setting extends BaseModel {
    /**
     * Define the icon to use in the settings menu
     **/
    static propertyIcon(property, _default) {
        switch (property) {
            case 'customer':
                return 'vcard';
            case 'images':
                return 'photo';
            case 'pos':
                return 'credit-card';
            case 'eshop':
                return 'globe';
            case 'pdf':
                return 'file-pdf-o';
            case 'mobile':
                return 'mobile';
            case 'payment':
                return 'credit-card';
            case 'delivery':
                return 'truck';
            case 'l10n':
                return 'flag';
            case 'integrations':
                return 'external-link';
            default:
                return _default;
        }
    }

    /**
     * Define the Field input to use to change
     * each setting
     **/
    static propertyForm(t, setting, property, tickettypes, salepoints, articles, articleCategories, postCategories, places, overrides) {
        const noInput = (order, role) => { return { order, role, input: null }; };
        const strBoolInput = (order, property, selectText, role) => { return { order, role, input: <StringBoolean name={ property } defaultValue={Utils.getDotified(setting, property)} form={form} selectText={selectText} /> }; };
        const durationBoolInput = (order, property, selectText, role) => ({
            order,
            role,
            input: (
                <StringBoolean name={ property } defaultValue={Utils.getDotified(setting, property)} form={form} selectText={selectText}>
                    <BookingPolicyField name={property} defaultValue={Utils.getDotified(setting, property)} />
                </StringBoolean>
            )
        });
        const emailInput = (order, property, role) => { return { order, role, input: <EmailField name={ property } /> }; };
        const textInput = (order, property, role) => { return { order, role, input: <Field component="input" className="form-control" type="text" name={ property } /> }; };
        const translatableInput = (order, property, role) => { return { order, role, input: <TranslatableField component="input" name={ property } optional /> }; };
        const translatableTextarea = (order, property, role) => { return { order, role, input: <TranslatableField component="textarea" rows="3" name={ property } optional /> }; };
        const translatableEmailInput = (order, property, role) => { return { order, role, input: <EmailField translatable name={ property } /> }; };
        const translatablePhoneInput = (order, property, role) => { return { order, role, input: <TranslatableField component={PhoneField} name={ property } /> }; };
        const typeSelect = (order, property, role) => { return { order, role, input: <CustomerTypeField name={property} /> }}
        const numberInput = (order, property, role) => { return { order, role, input: <Field component="input" className="form-control" type="number" name={ property } /> }; };
        const extraCurrenciesInput = (order, property, role) => { return { order, role, input: <ExtraCurrenciesField name={ property } setting={ setting }/> }; };
        const urlInput = (order, property, role) => { return { order, role, input: <Field component="input" className="form-control" type="url" name={ property } pattern="https://.*" /> }; };
        const tickettypesInput = (order, property, role) => { return  { order, role, input: ( <TickettypesField name={ property } tickettypes={ tickettypes }/>) }; };
        const posPaymentMethodsInput = (order, property, role) => { return  { order, role, input: ( <PaymentMethodsField name={ property } />) }; };
        const eshopPaymentMethodsInput = (order, property, role) => { return  { order, role, input: ( <PaymentMethodsField name={ property } forEshop={ true } />) }; };
        const colorPickerInput = (order, property, role) => { return  { order, role, input: ( <ColorPickerField name={ property } />) }; };
        const salepointInput = (order, property, role) => { return  { order, role, input: ( <SalepointsField name={ property } single salepoints={ salepoints } />) }; };
        const salepointsInput = (order, property, role) => { return  { order, role, input: ( <SalepointsField name={ property } salepoints={ salepoints } />) }; };
        const articlesInput = (order, property, role) => { return  { order, role, input: ( <ArticlesField name={ property } articles={ articles } single />) }; };
        const articleCategoryInput = (order, property, role) => { return  { order, role, input: ( <CategoriesField name={ property } categories={ articleCategories } />) }; };
        const postCategoryInput = (order, property, role) => { return  { order, role, input: ( <PostCategoriesField name={ property } postCategories={ postCategories } />) }; };
        const userDataFieldsInput = (order, property, role) => { return  { order, role, input: ( <UserDataField name={ property } />) }; };
        const envsField = (order, property, role) => { return  { order, role, input: ( <EnvsField name={ property } />) }; };
        const donationModesInput = (order, property, role) => { return  { order, role, input: ( <DonationModesField name={ property } />) }; };
        const registrationCallbackModesInput = (order, property, role) => { return  { order, role, input: ( <RegistrationCallbackModesField name={ property } />) }; };
        const twintMethodInput = (order, property, role) => { return  { order, role, input: ( <TwintMethodField name={ property } />) }; };
        const paymentMethodsInput = (order, role) => { return  { order, role, hideLabel: true, input: ( <PaymentMethodsForm setting={setting} />) }; };
        const deliveryTypesInput = (order, role) => { return  { order, role, hideLabel: true, input: ( <DeliveryTypesForm />) }; };
        const localeInput = (order, property, role) => { return  { order, role, input: ( <LocalesField name={ property } single />) }; };
        const localesInput = (order, property, role) => { return  { order, role, input: ( <LocalesField name={ property } />) }; };
        const inlineImageInput = (order, property, role) => { return  { order, hideLabel: true, role, input: ( <SettingsImagesField name={ property } { ...overrides }/>) }; };
        const showCalculatorInput = (order, property, role) => { return  { order, role, input: ( <ShowCalculatorField name={ property }/>) }; };
        const mobileInput = (order, property, role) => { return  { order, role, hideLabel: true, input: (<SettingsMobileField tickettypes={ tickettypes } salepoints={ salepoints } articles={ articles } articlecategories={ articleCategories } postCategories={ postCategories } places={ places } />) }; };
        const pdfInput = (order, property, role) => { return  { order, role, hideLabel: true, input: (<PdfLayouts setting={setting} layouts={setting?.pdf?.layouts || []} inSettings />) }; };
        const integrationInput = (order, property) => { return  { order, property, hideLabel: true, input: ( <IntegrationsForm />) }; };
        const booleanInput = (order, property, role) => {
            return  {
                order,
                role,
                input: (
                    <ToggleField name={ property } />
                )
            };
        };

        switch (property) {
            // sections order
            case 'customer': return noInput(1);
            case 'images': return inlineImageInput(2, property);
            case 'pos': return noInput(3);
            case 'eshop': return noInput(4);
            case 'payment': return noInput(5);
            case 'delivery': return noInput(6);
            case 'pdf': return noInput(7);
            case 'mobile': return mobileInput(8, property);
            case 'l10n': return noInput(9);
            case 'integrations': return integrationInput(11, property);

            case 'customer.name': return translatableInput(1, property);
            case 'customer.name_short': return translatableInput(2, property);
            case 'customer.phone': return translatablePhoneInput(3, property);

            case 'customer.address': return translatableTextarea(4, property);
            case 'customer.addr_line0': return translatableInput(5, property);
            case 'customer.addr_line1': return translatableInput(6, property);
            case 'customer.addr_line2': return translatableInput(7, property);
            case 'customer.addr_line3': return translatableInput(8, property);
            case 'customer.assets_path': return translatableInput(9, property);
            case 'customer.type': return typeSelect(10, property);

            case 'customer.email': return noInput(10);
            case 'customer.email.mail': return translatableEmailInput(1, property);
            case 'customer.email.bcc': return emailInput(1, property);
            case 'customer.email.errors_to': return emailInput(1, property);
            case 'customer.email.override_to': return emailInput(1, property);
            // bwc
            case 'customer.mail': return translatableEmailInput(4, property);

            case 'customer.vat': return translatableInput(6, property);
            case 'customer.assets_path': return translatableInput(7, property);
            case 'customer.logo': return translatableInput(8, property);
            case 'customer.favicon': return translatableInput(9, property);
            case 'customer.appicon': return translatableInput(10, property);
            case 'customer.tax': return noInput(11);
            case 'customer.tax.vat_articles': return numberInput(12, property);
            case 'customer.tax.vat_tickets': return numberInput(13, property);

            case 'l10n.currency': return textInput(1, property);
            case 'l10n.extra_currencies': return extraCurrenciesInput(2, property);
            case 'l10n.default_timezone': return textInput(3, property, 'superadmin');
            case 'l10n.locales': return noInput(4);
            case 'l10n.strftime_date_format': return textInput(5, property, 'superadmin');
            case 'l10n.strftime_datetime_format': return textInput(6, property, 'superadmin');
            case 'l10n.locales.all': return localesInput(7, property);
            case 'l10n.locales.default': return localeInput(8, property);
            case 'l10n.locales.display': return translatableInput(9, property);
            case 'l10n.charset': return textInput(10, property, 'superadmin');

            case 'pdf.layouts': return pdfInput(1, property);

            case 'pos.payment': return noInput(100);
            case 'pos.booking_cancelation_policy': return durationBoolInput(1, property, ["settings.pos.default_policy", "settings.pos.no_policy", "settings.pos.custom_policy"]);
            case 'pos.show_past_screenings_button': return booleanInput(1, property);
            case 'pos.cancel_items': return booleanInput(1, property);
            case 'pos.show_print_addition_button': return booleanInput(1, property);
            case 'pos.show_send_order_button': return booleanInput(1, property);
            case 'pos.requested_buyer_data': return userDataFieldsInput(1, property);
            case 'pos.required_buyer_data': return userDataFieldsInput(1, property);
            case 'pos.screening_capacity_warning_level': return numberInput(80, property);
            case 'pos.hide_screenings_started_since': return numberInput(81, property);
            case 'pos.additional_css': return urlInput(90, property);
            case 'pos.additional_js': return urlInput(91, property);

            case 'pos.automatic_printing': return noInput(200);
            case 'pos.automatic_printing.tickets': return booleanInput(1, property);
            case 'pos.automatic_printing.tickets_receipts': return booleanInput(2, property);
            case 'pos.automatic_printing.bookings': return booleanInput(3, property);
            case 'pos.automatic_printing.articles_receipts': return booleanInput(4, property);

            case 'eshop.url_overrides': return noInput(100);
            case 'eshop.registration': return noInput(200);
            case 'eshop.payment': return noInput(300);
            case 'eshop.email': return noInput(400);
            case 'eshop.newsletter_registration': return noInput(500);
            case 'eshop.survey_registration': return noInput(600);
            case 'eshop.donation': return noInput(700);

            case 'eshop.uri': return textInput(1, property);
            case 'eshop.is_publicly_available': return booleanInput(2, property);
            case 'eshop.allow_search_engines': return booleanInput(3, property);
            case 'eshop.requested_buyer_data': return userDataFieldsInput(4, property);
            case 'eshop.required_buyer_data': return userDataFieldsInput(5, property);
            case 'eshop.max_tickets_in_cart': return numberInput(6, property);
            case 'eshop.max_tickets_per_screening': return numberInput(7, property);
            case 'eshop.iframe_integration': return booleanInput(8, property);
            case 'eshop.enable_promo_code': return booleanInput(9, property);
            case 'eshop.show_available_seats_to_customers': return booleanInput(9, property);
            case 'eshop.booking_cancelation_policy': return durationBoolInput(1, property, ["settings.pos.default_policy", "settings.pos.no_policy", "settings.pos.custom_policy"]);

            case 'eshop.registration.enabled': return booleanInput(1, property);
            case 'eshop.registration.salepoints': return salepointsInput(2, property);
            case 'eshop.url_overrides.save_referer_for_host': return textInput(1, property);
            case 'eshop.url_overrides.home_url': return translatableInput(2, property);
            case 'eshop.url_overrides.screenings_url': return strBoolInput(3, property, ['settings.eshop.url_overrides.default_url', 'settings.eshop.url_overrides.no_url', 'settings.eshop.url_overrides.custom_url']);
            case 'eshop.url_overrides.pass_url': return strBoolInput(4, property, ['settings.eshop.url_overrides.default_url', 'settings.eshop.url_overrides.no_url', 'settings.eshop.url_overrides.custom_url']);
            case 'eshop.url_overrides.article_url': return strBoolInput(5, property, ['settings.eshop.url_overrides.default_url', 'settings.eshop.url_overrides.no_url', 'settings.eshop.url_overrides.custom_url']);
            case 'eshop.url_overrides.ticket_url': return strBoolInput(4, property, ['settings.eshop.url_overrides.default_url', 'settings.eshop.url_overrides.no_url', 'settings.eshop.url_overrides.custom_url']);
            case 'eshop.url_overrides.terms_conditions_url': return translatableInput(6, property);
            case 'eshop.url_overrides.privacy_policy_url': return translatableInput(7, property);
            case 'eshop.url_overrides.data_protection_url': return translatableInput(8, property);
            case 'eshop.url_overrides.sanitary_measures_url': return translatableInput(9, property);
            case 'eshop.newsletter_registration.modes': return registrationCallbackModesInput(1, property);
            case 'eshop.newsletter_registration.auto_subscribe': return booleanInput(1, property);
            case 'eshop.survey_registration.modes': return registrationCallbackModesInput(1, property);
            case 'eshop.survey_registration.auto_subscribe': return booleanInput(1, property);
            case 'eshop.donation.modes': return donationModesInput(1, property);
            case 'eshop.donation.article': return articlesInput(1, property);
            case 'eshop.email': return textInput(1, property);
            case 'eshop.newsletter_registration': return textInput(1, property);
            case 'eshop.survey_registration': return textInput(1, property);
            case 'eshop.additional_css': return urlInput(90, property);
            case 'eshop.additional_js': return urlInput(91, property);

            case 'payment.methods': return paymentMethodsInput(1);
            case 'delivery.types': return deliveryTypesInput(1);


            case 'mobile.production.kronosUri':
            case 'mobile.preprod.kronosUri':
            case 'mobile.testing.kronosUri':
                return textInput(1, property, 'superadmin');
            case 'mobile.production.kronosApiKey':
            case 'mobile.preprod.kronosApiKey':
            case 'mobile.testing.kronosApiKey':
                return textInput(2, property, 'superadmin');
            case 'mobile.production.salepointId':
            case 'mobile.preprod.salepointId':
            case 'mobile.testing.salepointId':
                return salepointInput(3, property);
            case 'mobile.production.disableUserAccount':
            case 'mobile.preprod.disableUserAccount':
            case 'mobile.testing.disableUserAccount':
                return booleanInput(4, property);
            case 'mobile.production.disableVotes':
            case 'mobile.preprod.disableVotes':
            case 'mobile.testing.disableVotes':
                return booleanInput(5, property);
            case 'mobile.production.disableFavorites':
            case 'mobile.preprod.disableFavorites':
            case 'mobile.testing.disableFavorites':
                return booleanInput(6, property);
            case 'mobile.production.apiKey':
            case 'mobile.preprod.apiKey':
            case 'mobile.testing.apiKey':
                return textInput(7, property, 'superadmin');
            case 'mobile.production.engineUri':
            case 'mobile.preprod.engineUri':
            case 'mobile.testing.engineUri':
                return textInput(8, property, 'superadmin');
            case 'mobile.production.screeningsFilters':
            case 'mobile.preprod.screeningsFilters':
            case 'mobile.testing.screeningsFilters':
                return noInput(100);
            case 'mobile.production.homeDisplay':
            case 'mobile.preprod.homeDisplay':
            case 'mobile.testing.homeDisplay':
                return noInput(200);
            case 'mobile.production.homeDisplay.showCarousel':
            case 'mobile.preprod.homeDisplay.showCarousel':
            case 'mobile.testing.homeDisplay.showCarousel':
                return booleanInput(201, property);
            case 'mobile.production.homeDisplay.showFeaturedMovie' :
            case 'mobile.preprod.homeDisplay.showFeaturedMovie' :
            case 'mobile.testing.homeDisplay.showFeaturedMovie' :
                return booleanInput(202, property);
            case 'mobile.production.homeDisplay.showDiscover' :
            case 'mobile.preprod.homeDisplay.showDiscover' :
            case 'mobile.testing.homeDisplay.showDiscover' :
                return booleanInput(203, property);
            case 'mobile.production.homeDisplay.showRestaurants' :
            case 'mobile.preprod.homeDisplay.showRestaurants' :
            case 'mobile.testing.homeDisplay.showRestaurants' :
                return booleanInput(204, property);
            case 'mobile.production.homeDisplay.showOnScreenMovies' :
            case 'mobile.preprod.homeDisplay.showOnScreenMovies' :
            case 'mobile.testing.homeDisplay.showOnScreenMovies' :
                return booleanInput(205, property);
            case 'mobile.production.homeDisplay.showTodayMovies' :
            case 'mobile.preprod.homeDisplay.showTodayMovies' :
            case 'mobile.testing.homeDisplay.showTodayMovies' :
                return booleanInput(206, property);
            case 'mobile.production.homeDisplay.showSections' :
            case 'mobile.preprod.homeDisplay.showSections' :
            case 'mobile.testing.homeDisplay.showSections' :
                return booleanInput(207, property);
            case 'mobile.production.homeDisplay.showGenres' :
            case 'mobile.preprod.homeDisplay.showGenres' :
            case 'mobile.testing.homeDisplay.showGenres' :
                return booleanInput(208, property);
            case 'mobile.production.homeDisplay.showGoodDeals' :
            case 'mobile.preprod.homeDisplay.showGoodDeals' :
            case 'mobile.testing.homeDisplay.showGoodDeals' :
                return booleanInput(209, property);
            case 'mobile.production.shop':
            case 'mobile.preprod.shop':
            case 'mobile.testing.shop':
                return noInput(300);
            case 'mobile.production.shop.rootCategoryId':
            case 'mobile.preprod.shop.rootCategoryId':
            case 'mobile.testing.shop.rootCategoryId':
                return articleCategoryInput(300, property);
            case 'mobile.production.posts':
            case 'mobile.preprod.posts':
            case 'mobile.testing.posts':
                return noInput(400);
            case 'mobile.production.posts.discover' :
            case 'mobile.preprod.posts.discover' :
            case 'mobile.testing.posts.discover' :
                return postCategoryInput(401, property);
            case 'mobile.production.posts.goodDeals' :
            case 'mobile.preprod.posts.goodDeals' :
            case 'mobile.testing.posts.goodDeals' :
                return postCategoryInput(402, property);
            case 'mobile.production.posts.pubs' :
            case 'mobile.preprod.posts.pubs' :
            case 'mobile.testing.posts.pubs' :
                return noInput(403);
            case 'mobile.production.posts.pubs.home' :
            case 'mobile.preprod.posts.pubs.home' :
            case 'mobile.testing.posts.pubs.home' :
                return postCategoryInput(404, property);
            case 'mobile.production.posts.pubs.shop' :
            case 'mobile.preprod.posts.pubs.shop' :
            case 'mobile.testing.posts.pubs.shop' :
                return postCategoryInput(405, property);
            case 'mobile.production.posts.pubs.program' :
            case 'mobile.preprod.posts.pubs.program' :
            case 'mobile.testing.posts.pubs.program' :
                return postCategoryInput(406, property);
            case 'mobile.production.posts.news' :
            case 'mobile.preprod.posts.news' :
            case 'mobile.testing.posts.news' :
                return noInput(407);
            case 'mobile.production.posts.news.rootCategoryId' :
            case 'mobile.preprod.posts.news.rootCategoryId' :
            case 'mobile.testing.posts.news.rootCategoryId' :
                return postCategoryInput(408, property);
            case 'mobile.production.featured':
            case 'mobile.preprod.featured':
            case 'mobile.testing.featured':
                return noInput(500);
            case 'mobile.production.featured.screening':
            case 'mobile.preprod.featured.screening':
            case 'mobile.testing.featured.screening':
                return textInput(501, property);
            case 'mobile.production.featured.movie':
            case 'mobile.preprod.featured.movie':
            case 'mobile.testing.featured.movie':
                return textInput(502, property);
            case 'mobile.production.images':
            case 'mobile.preprod.images':
            case 'mobile.testing.images':
                return noInput(600);
            case 'mobile.production.images.format':
            case 'mobile.preprod.images.format':
            case 'mobile.testing.images.format':
                return numberInput(601, property);
            case 'mobile.production.images.nb_per_row':
            case 'mobile.preprod.images.nb_per_row':
            case 'mobile.testing.images.nb_per_row':
                return numberInput(602, property);
            case 'mobile.production.theme':
            case 'mobile.preprod.theme':
            case 'mobile.testing.theme':
                return noInput(700);
            case 'mobile.production.theme.colors':
            case 'mobile.preprod.theme.colors':
            case 'mobile.testing.theme.colors':
                return noInput(700);
            case 'mobile.production.theme.colors.background' :
            case 'mobile.preprod.theme.colors.background' :
            case 'mobile.testing.theme.colors.background' :
                return colorPickerInput(700, property);
            case 'mobile.production.theme.colors.primary' :
            case 'mobile.preprod.theme.colors.primary' :
            case 'mobile.testing.theme.colors.primary' :
                return colorPickerInput(701, property);
            case 'mobile.production.theme.colors.primary-inverse' :
            case 'mobile.preprod.theme.colors.primary-inverse' :
            case 'mobile.testing.theme.colors.primary-inverse' :
                return colorPickerInput(701, property);
            case 'mobile.production.theme.colors.nav':
            case 'mobile.preprod.theme.colors.nav':
            case 'mobile.testing.theme.colors.nav':
                return noInput(700);
            case 'mobile.production.theme.colors.nav.background' :
            case 'mobile.preprod.theme.colors.nav.background' :
            case 'mobile.testing.theme.colors.nav.background' :
                return colorPickerInput(702, property);
            case 'mobile.production.theme.colors.nav.border' :
            case 'mobile.preprod.theme.colors.nav.border' :
            case 'mobile.testing.theme.colors.nav.border' :
                return colorPickerInput(703, property);
            case 'mobile.production.theme.colors.nav.activeColor' :
            case 'mobile.preprod.theme.colors.nav.activeColor' :
            case 'mobile.testing.theme.colors.nav.activeColor' :
                return colorPickerInput(704, property);
            case 'mobile.production.theme.colors.nav.inactiveColor' :
            case 'mobile.preprod.theme.colors.nav.inactiveColor' :
            case 'mobile.testing.theme.colors.nav.inactiveColor' :
                return colorPickerInput(705, property);

            default: return textInput(1000, property);
        }
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();

        prepared.delivery.types = prepared.delivery.types.map(type => {
            Utils.cleanTranslatableProperty(type, "name");
            type.rules = (type.rules || []).map(rule => {
                _.mapKeys(rule, (value, property) => {
                    if (['price', 'min_cart_amount'].includes(property)) {
                        _.mapKeys(rule[property], (price, currency) => {
                            rule[property][currency] = Number(price);
                            if (_.isNaN(rule[property][currency]))
                                rule[property][currency] = 0;
                        });
                    } else if (property != 'countries') {
                        rule[property] = Number(rule[property]);
                        if (_.isNaN(rule[property]))
                            delete rule[property];
                    }
                });

                return rule;
            });

            return type;
        });

        if (prepared.pdf?.layouts?.length > 0)
            prepared.pdf.layouts = prepared.pdf.layouts.map(layout => ({
                ...layout,
                dimensions: {
                    ...layout.dimensions,
                    width: parseFloat(layout.dimensions.width),
                    height: parseFloat(layout.dimensions.height)
                }
            }));

        return prepared;
    }

    findImage(type, lang) {
        const images = this.images || [];
        if (images.length === 0)
            return null;

        // check the type (mandatory)
        let matchingType = images.filter(image => image.type === type);
        if (matchingType.length === 0) {
            // logo to logo A4 to logo thermal fallback
            if (type == Utils.IMAGE_TYPE_LOGO) {
                matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_LOGO_A4);
                if (matchingType.length === 0) {
                    matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_LOGO_THERMAL);
                }
                if (matchingType.length === 0) {
                    return null;
                }
            }
            // logo A4 to logo to logo thermal fallback
            else if (type == Utils.IMAGE_TYPE_LOGO_A4) {
                matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_LOGO);
                if (matchingType.length === 0) {
                    matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_LOGO_THERMAL);
                }
                if (matchingType.length === 0) {
                    return null;
                }
            }
            // logo thermal to logo A4 to logo fallback
            else if (type == Utils.IMAGE_TYPE_LOGO_THERMAL) {
                matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_LOGO_A4);
                if (matchingType.length === 0) {
                    matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_LOGO);
                }
                if (matchingType.length === 0) {
                    return null;
                }
            }
            // partners to partners A4 to partners thermal fallback
            else if (type == Utils.IMAGE_TYPE_PARTNERS) {
                matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_PARTNERS_A4);
                if (matchingType.length === 0) {
                    matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_PARTNERS_THERMAL);
                }
                if (matchingType.length === 0) {
                    return null;
                }
            }
            // partners A4 to partners to partners thermal fallback
            else if (type == Utils.IMAGE_TYPE_PARTNERS_A4) {
                matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_PARTNERS);
                if (matchingType.length === 0) {
                    matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_PARTNERS_THERMAL);
                }
                if (matchingType.length === 0) {
                    return null;
                }
            }
            // partners thermal to partners A4 to partners fallback
            else if (type == Utils.IMAGE_TYPE_PARTNERS_THERMAL) {
                matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_PARTNERS_A4);
                if (matchingType.length === 0) {
                    matchingType = images.filter(image => image.type === Utils.IMAGE_TYPE_PARTNERS);
                }
                if (matchingType.length === 0) {
                    return null;
                }
            }
            if (matchingType.length === 0) {
                return null;
           }
        }

        // check the lang
        const matchinglang = matchingType.filter(image => image.lang === lang);
        if (matchinglang.length > 0)
            return matchinglang[0];

        // fallback to images without lang
        const withoutLang = matchingType.filter(image => (image.lang === null || image.lang === ""));
        if (withoutLang.length > 0)
            return withoutLang[0];

        // fallback to unmatching lang
        return matchingType[0];
    };
}

export default Setting;
