import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { changeSetting } from '../../redux/modules/settings';
import { Setting } from '../../models';
import { Screen, Utils } from '../../services';
import { LanguageSelector } from '../../components';
import { Row, Col, Button, ButtonGroup, Card, CardBody, CardText, CardTitle, Input, Label } from "reactstrap";
import _ from "lodash";

import { PdfLayout } from '../../models';
import { PdfLayoutOptions } from './PdfLayoutOptions';
import { PdfWidgetOptions } from './PdfWidgetOptions';
import { PdfWidgetsBrowser } from './PdfWidgetsBrowser';
import { DragAndResize } from "./DragAndResize";
import moment from 'moment';

import { addPDFlayout, duplicatePDFlayout, deletePDFlayout, addLayoutWidget, deleteLayoutWidget, duplicateLayoutWidget } from '../../redux/modules/settings';
import { loadFonts, loadPricinglists, loadScreenings, loadProgrammations, loadPlaces, loadTickettypes } from "../../redux";
import ItemsList from '../ItemsList/ItemsList';

import './PdfLayouts.css';


const PdfLayouts = ({ }) => {
    const { fonts }   = useSelector(state => state.fonts);
    const { setting } = useSelector(state => state.settings);
    const layouts     = setting.pdf.layouts || [];

    const dispatch     = useDispatch();
    const { t, i18n }  = useTranslation();
    const { isMobile } = useSelector(state => state.ui);

    const widgetRef = useRef([]);

    const [mainContainerWidth, setMainContainerWidth]   = useState(PdfLayout.sizes('Custom').width);
    const [mainContainerHeight, setMainContainerHeight] = useState(PdfLayout.sizes('Custom').height);
    const [widgets, setWidgets]                         = useState([]);
    const [inEdition, setInEdition]                     = useState(false);

    const [currentWidgetIndex, setCurrentWidgetIndex]   = useState(-1);
    const [currentLayout, setCurrentLayout]             = useState();
    const [currentLayoutIndex, setCurrentLayoutIndex]   = useState(-1);
    const [currentOptionsGroup, setCurrentOptionsGroup] = useState('position');
    const [previewLanguage, setPreviewLanguage]         = useState(i18n.language);
    const [pdfSize, setPdfSize]                         = useState("Custom");
    const [scale, setScale]                             = useState(1);

    const { programmations } = useSelector(state => state.programmations);
    const { pricinglists }   = useSelector(state => state.pricinglists);
    const { screenings }     = useSelector(state => state.screenings);
    const { places }         = useSelector(state => state.places);
    const { tickettypes }    = useSelector(state => state.tickettypes);

    const [displayPrice, setDisplayPrice]   = useState(false);
    const [programmation, setProgrammation] = useState(false);
    const [place, setPlace]                 = useState(false);
    const [tickettype, setTickettype]       = useState(false);
    const [pricinglist, setPricinglist]     = useState(false);
    const [priceSelected, setPriceSelected] = useState(false);
    const [oneTimePass, setOneTimePass]     = useState(false);

    useEffect(() => {
        if (layouts && currentLayoutIndex > -1) {
            const layout = layouts[currentLayoutIndex];
            setPdfPageSize(layout.size);
            setWidgets(layout.widgets);
        }
    }, [currentLayoutIndex, layouts, scale])

    useEffect(() => {
        Screen.setScale(scale);
    }, [scale]);

    useEffect(() => {
        if (!(currentLayoutIndex > -1) || !setting || !widgets)
            return;

        const newSetting = { ...setting };
        newSetting.pdf.layouts[currentLayoutIndex].widgets = [...widgets];
        dispatch(changeSetting(newSetting));
    }, [widgets]);

    useEffect(() => {
        dispatch(loadScreenings({
            start_at_gte: null,
            stop_at_lte: null,
            places_ids: []
        }));
        dispatch(loadPricinglists());
        dispatch(loadProgrammations());
        dispatch(loadPlaces());
        dispatch(loadTickettypes());
        dispatch(loadFonts());
    }, [])

    useEffect(() => {
        if (!fonts || !fonts.length)
            return;

        fonts.forEach(font => {
            loadFont(font.name, font.url);
            Object.keys(font.variants).map(key => {
                const variant = font.variants[key];
                variant.url && loadFont(variant.name, variant.url);
            });
        });
    }, [fonts]);

    const loadFont = async (name, url) => {
        const fontFace = new FontFace(name, `url(${url})`, {});
        // wait for font to be loaded
        try {
            await fontFace.load();
            // add font to document
            document.fonts.add(fontFace);
            // enable font with CSS class
            document.body.classList.add("fonts-loaded");
        } catch (e) {
            console.error('Could not load font', name, url);
        }
    }

    const addLayout = () => dispatch(addPDFlayout());
    const duplicateLayout = () => dispatch(duplicatePDFlayout(currentLayoutIndex));
    const deleteLayout = () => {
        setCurrentLayoutIndex(-1);
        setCurrentWidgetIndex(-1);
        dispatch(deletePDFlayout(currentLayoutIndex));
    }

    const addWidget = (type, subType) => {
        const widget = PdfLayout.createWidget({ type, subType });
        dispatch(addLayoutWidget(currentLayoutIndex, widget.toPlainObject()))
        if (widget.isEditable())
            setTimeout(() => setCurrentWidgetIndex(widgets.length), 200);
    };

    const duplicateWidget = () => {
        dispatch(duplicateLayoutWidget(currentLayoutIndex, currentWidgetIndex))
        setTimeout(() => setCurrentWidgetIndex(widgets.length), 200);
    };

    const deleteWidget = () => {
        dispatch(deleteLayoutWidget(currentLayoutIndex, currentWidgetIndex))
        setCurrentWidgetIndex(-1);
    };

    const moveWidgetBackward = () => {
        const newWidgets = [widgets[currentWidgetIndex]].concat(
            widgets.filter((w, i) => i != currentWidgetIndex)
        );
        setWidgets(newWidgets);
        setCurrentWidgetIndex(0);
    };

    const moveWidgetForward = () => {
        const newWidgets = widgets.filter((w, i) => i != currentWidgetIndex).concat([
            widgets[currentWidgetIndex]
        ]);
        setWidgets(newWidgets);
        setCurrentWidgetIndex(widgets.length - 1);
    };

    const handleChangeFromDragAndResize = (widget, index) => {
        const newWidgets = widgets;
        newWidgets[index] = {
            ...newWidgets[index],
            position: widget.position,
            dimensions: widget.dimensions
        };
        setWidgets([...newWidgets]);
    };

    const handleNewOptions = (widget) => {
        const newWidgets = _.cloneDeep(widgets);
        newWidgets[currentWidgetIndex] = { ...widget };
        setWidgets(newWidgets);
    };

    const selectImage = (event, index) => {
        const file = event.target.files[0];
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            const newWidgets = widgets;
            const widget = newWidgets[index];
            widget.options.rfc2397_data = fileReader.result;
            newWidgets[index] = widget;
            setWidgets([...newWidgets]);
        }
        fileReader.readAsDataURL(file)
    };

    const setPdfPageSize = (value) => {
        setPdfSize(value);
        const layout = layouts[currentLayoutIndex];

        if (value === 'Custom') {
            setMainContainerWidth(layout.dimensions.width)
            setMainContainerHeight(layout.dimensions.height)
        } else {
            const size = PdfLayout.sizes(value);
            layout.dimensions = {
                width: size.width,
                height: size.height
            };
            setMainContainerWidth(size?.width);
            setMainContainerHeight(size?.height);
        }
    }

    const loadOverrideTickettypes = (id) => {
        const tickettype = id ? tickettypes.find(t => t._id === id) : false;
        setTickettype(tickettype);
        setPricinglist(tickettype.pricings);
        setPriceSelected(false);
        setDisplayPrice(true);
        if (id === "one-time-pass") {
            setOneTimePass(true);

        } else {
            setOneTimePass(false);
            setProgrammation(false);
            setPlace(false);
        }
    }
    const loadOverridePrice = (id) => {
        if (id.length > 0) {
            for (const key in pricinglist) {
                if (pricinglist[key].name[i18n.language] === id) {
                    setPriceSelected(pricinglist[key]);
                }
            }
        } else {
            setProgrammation(false);
            setPlace(false);
        }
    }

    const loadOverridePricingList = (ids) => {
        const [pricingListId, pricingId] = ids.split(":");

        const pricinglist = pricinglists.find((p) => p._id == pricingListId);
        if (!pricinglist)
            return;

        const price = pricinglist.pricings[pricingId];

        if (price) {
            setPriceSelected(price);
        } else {
            setProgrammation(false);
            setPlace(false);
        }
    }
    const loadOverrideEvents = (id) => {
        if (id.length > 0) {
            const prog  = id ? programmations.find(p => p.id === id) : false;
            const event = id ? screenings.find(s => s._id === id) : false;

            if (prog) {
                const place = id ? places.find(p => p._id === prog.place_id) : false;
                setProgrammation(prog);
                setPlace(place);
            }
            if (event) {
                const place = id ? places.find(p => p._id === event.cinema_hall._id) : false;
                setProgrammation(event);
                setPlace(place);
            }
        } else {
            setProgrammation(false);
            setPlace(false);
        }
    }

    const onSelectLayout = (type, index) => {
        setCurrentWidgetIndex(-1);

        if (type === '__add')
            return addLayout();

        setCurrentLayoutIndex(index)
        setCurrentLayout(layouts[index])
    }

    const history = useHistory();
    const goToTicketType = (tickettypeId, index) => {
        if (tickettypeId) {
            history.push('/tickettypes/edit/'+tickettypeId);
        }
    }

    const pdfLinkedToTicketTypes = (tickettypes || []).filter(tickettype => {
        return currentLayout && (tickettype.opaque?.layouts?.length > 0 && tickettype.opaque.layouts[currentLayout.target] === currentLayout._id);
    }).map((data, index) => ({
        key: data._id,
        title: data.name[i18n.language],
        selection: data._id,
        depth: 0,
        icon: 'tags'
    }));

    const selectWidget = (widget, index) => {
        setCurrentWidgetIndex(-1);
        // we let the options widget the time to reset
        setTimeout(() => {
            if (widget.isEditable())
                setCurrentWidgetIndex(index);
        }, 0);
    }

    const createGrid = () => {
        const gridArray = []
        for (let index = 0.5; index < mainContainerWidth; index += 0.5) {
            gridArray.push(<div key={`x-${index}`} style={{ left: `${Screen.cmToPx(index)}px`, y: 0, height: `${Screen.cmToPx(mainContainerHeight)}px`, width: '1px', backgroundColor: '#E5E5E5', position: 'absolute' }} />)
            if ((index * 10) % 10 === 0)
                gridArray.push(<div key={`xx-${index}`} style={{ left: `${Screen.cmToPx(index)}px`, y: 0, height: `${Screen.cmToPx(0.4)}px`, width: '1px', backgroundColor: '#858585', position: 'absolute' }} />)
            else
                gridArray.push(<div key={`xx-${index}`} style={{ left: `${Screen.cmToPx(index)}px`, y: 0, height: `${Screen.cmToPx(0.1)}px`, width: '1px', backgroundColor: '#858585', position: 'absolute' }} />)
        }

        for (let index = 0.5; index < mainContainerHeight; index += 0.5) {
            gridArray.push(<div key={`y-${index}`} style={{ x: 0, top: `${Screen.cmToPx(index)}px`, height: '1px', width: `${Screen.cmToPx(mainContainerWidth)}px`, backgroundColor: '#E5E5E5', position: 'absolute' }} />)
            if ((index * 10) % 10 === 0)
                gridArray.push(<div key={`yy-${index}`} style={{ x: 0, top: `${Screen.cmToPx(index)}px`, height: '1px', width: `${Screen.cmToPx(0.4)}px`, backgroundColor: '#858585', position: 'absolute' }} />)
            else
                gridArray.push(<div key={`yy-${index}`} style={{ x: 0, top: `${Screen.cmToPx(index)}px`, height: '1px', width: `${Screen.cmToPx(0.1)}px`, backgroundColor: '#858585', position: 'absolute' }} />)
        }

        return gridArray
    }

    const items = (layouts || []).map((data, index) => {
        return {
            key: `${data.name[i18n.language]}.${index}`,
            title: data.name[i18n.language],
            icon: 'th',
            active: index == currentLayoutIndex ? true : false,
            depth: 0,
            selection: ''
        }
    });
    items.push({
        key: '__add',
        selection: '__add',
        title: t("layouts.add_new_layout"),
        icon: 'plus',
        isButton: true,
        depth: 0
    });

    const icons = {
        'text_owner_info'      : 'user',
        'text_ticket_info'     : 'tag',
        'text_tickettype_info' : 'tags',
        'text_screening_info'  : 'film',
        'text_customer_info'   : 'gear',
        'text_environment_info': 'globe',
        'text_custom'          : 'commenting-o',
        'text_link'            : 'link',
        'image_library'        : 'photo',
        'image_custom'         : 'photo',
        'image_owner_contact'  : 'user',
        'image_qrcode'         : 'qrcode',
        'shape_frame'          : 'square-o',
        'shape_line'           : 'arrows-h'
    }

    const widgetsList = React.useMemo(() => {
        return (widgets || []).slice().reverse().map((w, index) => {
            const key = (widgets || []).length - index - 1;
            return {
                key: index,
                title: t(`layouts.${w.type}_${w.subType}`),
                icon: icons[w.type + '_' + w.subType],
                active: key == currentWidgetIndex,
                depth: 0,
                selection: PdfLayout.createWidget(w)
            }
        });
    }, [widgets, currentWidgetIndex]);

    return (
        <div className="PdfLayouts">
            <Row>
                <Col className="post_categories-browser" sm={2}>
                    <div className="TypeSelector">
                        <ItemsList
                            title={t('layouts.layouts')}
                            items={items}
                            onSelect={onSelectLayout}
                        />
                    </div>
                    {currentLayoutIndex > -1 &&
                        <>
                            <Card className="h-auto mb-3">
                                <CardBody>
                                    <CardTitle className="small">{t('settings.delivery.type.general_informations')}</CardTitle>
                                    <CardText tag="div">
                                        <PdfLayoutOptions
                                            index={currentLayoutIndex}
                                            pdfSize={pdfSize}
                                            setPdfSize={setPdfSize}
                                            setPdfPageSize={setPdfPageSize}
                                            setMainContainerWidth={setMainContainerWidth}
                                            setMainContainerHeight={setMainContainerHeight}
                                        />
                                        <Row>
                                            <Col>

                                                <ButtonGroup>
                                                    <Button onClick={() => duplicateLayout()} color='info' size="sm">
                                                        <i className="fa fa-copy"></i> {t("common.duplicate")}
                                                    </Button>
                                                    <Button type="submit" color="danger" onClick={deleteLayout}>
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                                        </span>
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </>
                    }
                    {pdfLinkedToTicketTypes.length > 0 &&
                        <div className="TypeSelector pdfLinkedToTicketTypes">
                            <ItemsList
                                title={t('settings.pdf.layout_link')}
                                items={pdfLinkedToTicketTypes}
                                onSelect={goToTicketType}
                            />
                        </div>
                    }
                </Col>
                <Col className="post_categories-browser" sm={10}>
                    {currentLayoutIndex > -1 &&
                        <>
                            <Row>
                                <Col sm={inEdition ? 8 : 12}>
                                    <div className={"topButtonsWrapper " + (isMobile ? "w-100" : "")}>
                                        <ButtonGroup className={isMobile ? "w-100" : ""}>
                                            <Button onClick={() => setInEdition(true)} color={inEdition ? 'primary' : 'dark'} size="sm">
                                                <i className="fa fa-pencil"></i> {t("settings.pdf.edit_mode")}
                                            </Button>
                                            <Button onClick={() => setInEdition(false)} color={!inEdition ? 'primary' : 'dark'} size="sm">
                                                <i className="fa fa-eye"></i> {t("settings.pdf.preview_mode.title")}
                                            </Button>
                                        </ButtonGroup>

                                        <LanguageSelector onChange={lang => setPreviewLanguage(lang)} />

                                        <div className={"rangeWrapper " + (!isMobile ? "pull-right w-50" : "w-100")}>
                                            <div className="range w-100">
                                                <i className="fa fa-search-minus" />
                                                <Input
                                                    type="range"
                                                    min={0.25}
                                                    max={3}
                                                    step={0.05}
                                                    value={scale}
                                                    onChange={e => setScale(e.target.value)}
                                                />
                                                <i className="fa fa-search-plus" />
                                            </div>
                                            {!isMobile &&
                                                <div className="rangeInfos">
                                                    <Input
                                                        type="number"
                                                        min={0.25}
                                                        max={3}
                                                        step={0.05}
                                                        value={parseInt(scale * 100)}
                                                        onChange={e => setScale(e.target.value / 100)}
                                                    />
                                                    %
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {!inEdition &&
                                        // Tickettypes list
                                        <div className={"dataTestButtonsWrapper d-flex"}>
                                            <Label className="mt-2">{t("settings.pdf.preview_mode.data_test")}</Label>
                                            <Input type="select" className="w-25 ml-3" onChange={e => loadOverrideTickettypes(e.target.value)}>
                                                <option value="">{t("settings.pdf.preview_mode.choose_tickettypes_list")}</option>
                                                {tickettypes && tickettypes.map(field => (
                                                    <option key={field.id} value={field._id}>
                                                            {Utils.localized_or_fallback(field.name, previewLanguage)}
                                                    </option>
                                                ))}
                                            </Input>

                                            {!oneTimePass && displayPrice && pricinglist &&
                                                // Pricinglist in tickettype
                                                <Input type="select" className="w-25 ml-3" onChange={e => loadOverridePrice(e.target.value)}>
                                                    <option value="">{t("settings.pdf.preview_mode.choose_price")}</option>
                                                    {Object.values(pricinglist).map((list, index) => (
                                                        <option key={index} value={list.name[i18n.language]}>
                                                            {Utils.localized_or_fallback(list.name, previewLanguage)}  - {list.price.CHF} {Object.keys(list.price)}
                                                        </option>
                                                    ))}
                                                </Input>
                                            }
                                            {oneTimePass &&
                                            <>
                                                {/* All Pricinglists */}
                                                <Input type="select" className="w-25 ml-3" onChange={e => loadOverridePricingList(e.target.value)}>
                                                    <option value="">{t("settings.pdf.preview_mode.choose_price")}</option>
                                                    {pricinglists.map(list => (
                                                        <optgroup key={list._id} label={list.name}>
                                                            {Object.keys(list.pricings).map((key, index) => (
                                                            <option
                                                                key={Utils.localized_or_fallback(list.pricings[key].name, previewLanguage)}
                                                                value={list._id +':'+ key}
                                                            >
                                                                {Utils.localized_or_fallback(list.pricings[key].name, previewLanguage)} - {list.pricings[key].price.CHF} {Object.keys(list.pricings[key].price)}
                                                            </option>
                                                            ))}
                                                        </optgroup>
                                                    ))}
                                                </Input>
                                                <Input type="select" className="w-auto ml-3" onChange={e => loadOverrideEvents(e.target.value)}>
                                                    <option value="">{t("settings.pdf.preview_mode.choose_movies_list")}</option>
                                                    {screenings && screenings.length > 0 && screenings.map(field => (
                                                        <option key={field.films[0]._id} value={field._id}>
                                                            {Utils.localized_or_fallback(field.title, previewLanguage) +' - '+ moment(field.start_at).format('LT l')}
                                                        </option>
                                                    ))}
                                                    {programmations && programmations.map(field => (
                                                        <option key={field.id} value={field.id}>
                                                            {Utils.localized_or_fallback(field.title, previewLanguage) +' - '+ moment(field.start_at).format('LT l')}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </>
                                            }
                                        </div>
                                    }
                                    <div className={"previewWrapper " + (isMobile ? "w-100" : "")}>
                                        <div className="preview" style={{
                                            height: `${Screen.cmToPx(mainContainerHeight)}px`,
                                            width: `${Screen.cmToPx(mainContainerWidth)}px`,
                                        }}>
                                            {inEdition && (
                                                <div style={{ width: `${Screen.cmToPx(mainContainerWidth)}px`, height: `${Screen.cmToPx(mainContainerHeight)}px` }}>
                                                    {createGrid()}
                                                </div>
                                            )}

                                            {widgets.map((data, index) => {
                                                const widget = PdfLayout.createWidget(data);
                                                return (
                                                    <DragAndResize
                                                        key={index}
                                                        widget={widget}
                                                        ref={el => widgetRef.current[index] = el}
                                                        active={index === currentWidgetIndex}
                                                        inEdition={inEdition}
                                                        lang={previewLanguage}
                                                        fonts={fonts}
                                                        setting={new Setting(setting)}
                                                        onClick={() => selectWidget(widget, index)}
                                                        onDelete={deleteWidget}
                                                        onChange={(data) => handleChangeFromDragAndResize(data, index)}
                                                        programmation={programmation}
                                                        place={place}
                                                        tickettype={tickettype}
                                                        pricing={priceSelected}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    {inEdition && widgets.length > 0 &&
                                    <>
                                        <CardTitle className="small">{t('layouts.list_widget')}</CardTitle>
                                        <div className="widgetsLists">
                                                <ItemsList
                                                items={widgetsList}
                                                onSelect={(widget, index) => selectWidget(widget, widgets.length - 1 - index)}
                                            />
                                        </div>
                                    </>
                                    }
                                    {inEdition && (currentWidgetIndex > -1) && (
                                    <>
                                        <CardTitle className="small">
                                            {t('layouts.edit_widget')} - {t(`layouts.${widgets[currentWidgetIndex].type}_${widgets[currentWidgetIndex].subType}`)}
                                        </CardTitle>
                                        <PdfWidgetOptions
                                            widget={PdfLayout.createWidget(widgets[currentWidgetIndex])}
                                            widgetRef={widgetRef.current[currentWidgetIndex]}
                                            currentGroup={currentOptionsGroup}
                                            onGroupChange={setCurrentOptionsGroup}
                                            onSubmit={handleNewOptions}
                                            onDuplicate={duplicateWidget}
                                            onBackward={moveWidgetBackward}
                                            onForward={moveWidgetForward}
                                            onDelete={deleteWidget}
                                            onClose={() => setCurrentWidgetIndex(-1)}
                                        />
                                    </>
                                    )}
                                    {inEdition && !(currentWidgetIndex > -1) && (
                                    <>
                                        <CardTitle className="small">
                                            {t('layouts.add_widget')}
                                        </CardTitle>
                                        <PdfWidgetsBrowser addWidget={addWidget} />
                                    </>
                                    )}
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </Row>
        </div>
    );
}

export default PdfLayouts;
