import BaseModel from './BaseModel';
import { TktInstance } from '../models';
import { Utils } from '../services';

/** Class representing an user. */
class User extends BaseModel {
    constructor(properties) {
        super(properties);

        if (this.tkt_instance) {
            this.tkt_instance = new TktInstance(this.tkt_instance);
        }

        // ensure settings is an object
        if (Array.isArray(this.settings))
            this.settings = Object.assign({}, this.settings);
    }

    userRole       = () => User.ROLE_USER;
    adminRole      = () => User.ROLE_ADMIN;
    superAdminRole = () => User.ROLE_SUPER_ADMIN;

    isUser       = () => this.isAdmin() || this.role === User.ROLE_USER;
    isAdmin      = () => this.isSuperAdmin() || this.role === User.ROLE_ADMIN;
    isSuperAdmin = () => this.abilities?.superadmin;

    // returns true if user role allows him to have the given role
    hasRole = (role) => {
        return {
            [User.ROLE_SUPER_ADMIN]: this.isSuperAdmin(),
            [User.ROLE_ADMIN]: this.isAdmin(),
            [User.ROLE_USER]: this.isUser()
        }[role];
    }

    can = (action, mod) => {
        if (this.abilities.superadmin)
            return true;

        // Read any
        if (action === 'read')
            return (this.abilities[mod].readOther || this.abilities[mod].readSelf)

        // Specific right
        if (this.abilities?.[mod]?.[action])
            return true;
        return false;
    }

    verifyAccessSections = (section) => {
        if (this.isSuperAdmin())
            return true;
        switch (section) {
            case 'operation':
                return this.canAccessOperation();
            case 'admin':
                return this.canAccessAdmin(section);
            case 'analysis':
                return this.canAccessAnalysis();
            default:
                return false;
        }
    }

    canAccessOperation = () => {
        return this.anyRightInSection('operation');
    }

    canAccessAdmin = () => {
        return this.anyRightInSection('admin');
    }

    canAccessAnalysis = () => {
        return this.can('readOther', 'stats');
    }

    anyRightInSection = (section) => {
        let result = false;
        Utils.getTopbarModules(section).map(mod => {
            if ((mod in this.abilities) && mod !== 'abilities_templates')
                Object.keys(this.abilities[mod]).map(ability => {
                    if (!result && this.abilities[mod][ability]) {
                        result = true;
                    }
                });
        });
        return result;
    }

    canAccess = (pathname) => {
        // Superadmin always gets his ways
        if (this.abilities.superadmin)
            return true;

        if (!pathname)
            return false;

        let [domain, controller, action, params] = pathname.split("/", 4);  // path array kronos.com [0] / module [1] / action [2]

        // email layouts follow the PDF layouts rules
        if (controller === 'email_layouts')
            controller = 'layouts';

        if (controller === 'devices' && this.can('editSelf', 'settings'))
            return true;

        // postgres screenings exception
        if (controller === 'oldscreenings')
            controller = "programmations";

        const currentModules = Utils.getCurrentModules(this.tkt_instance.options.modules);  //Get instance modules

        //If instance doesn't have modules, don't even try the rest + dashboards exceptions
        if (!currentModules.includes(controller) && controller !== "admin" && controller !== "operation" && controller !== "analysis" && controller !== "")
            return false;

        //Stats only has read
        if ((controller === "stats" || controller === "analysis") && this.can("readOther", "stats"))
            return true;

        if (controller === 'users' && ['edit', 'show'].includes(action) && this.id === params)
            return true;

        // events/event_type/actions/id exception
        if (action && ['movie', 'music_group', 'drama', 'service'].includes(action))
            action = params;

        if (action) {
            // www.kronos.com/basepath/actionpath
            switch (action) {
                case 'edit':
                    //TktInstance exception for abilities templates
                    if (controller === "tktInstances" && this.can('edit_templates', 'users') && !this.can('editSelf', 'tktInstances')) {
                        action = 'edit_templates';
                        action = 'users';
                    } else {
                        if (Utils.getMongoResources().includes(controller))
                            action = 'editOther';
                        else
                            action = 'editSelf';
                    }
                    break;
                case 'show':
                    if (Utils.getMongoResources().includes(controller))
                        action = 'readOther';
                    else
                        action = 'readSelf';
                    break;
                case 'new':
                        action = 'create';
                    break;
                default:
                    if (Utils.getMongoResources().includes(controller))
                        action += 'Other';
                    else
                        action += 'Self';
            }
            return this.can(action, controller);
        } else {

            // site root
            if (!controller)
                return true;

            if (controller === 'admin' || controller === 'operation')
                return this.verifyAccessSections(controller);

            // Message only has create
            if (controller === "messages" && this.can("create", "messages"))
                return true;

            // list fallback (kronos/screenings for example)
            if (this.can((Utils.getMongoResources().includes(controller) ? "readOther" : "readSelf"), controller))
                return true;
        }
        return false;
    }
}

User.GENDER_DEFAULT = '?';
User.GENDER_FEMALE  = 'F';
User.GENDER_MALE    = 'M';

User.ROLE_SUPER_ADMIN = 'super_admin';
User.ROLE_ADMIN       = 'admin';
User.ROLE_USER        = 'user';

User.genders = () => {
    return [
        User.GENDER_DEFAULT,
        User.GENDER_FEMALE,
        User.GENDER_MALE,
    ];
};

User.roles = () => {
    return [
        User.ROLE_SUPER_ADMIN,
        User.ROLE_ADMIN,
        User.ROLE_USER,
    ];
};

export default User;
