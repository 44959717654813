import RestClient from './RestClient';
import { User } from '../../models';

class Users extends RestClient {
    constructor() {
        super({
            "model": User,
            "entryPoint": "kuser",
            "resource": "user",
            "resources": "users"
        });
    }

    inactive() {
        return this.GET('/kuser/inactive')
            .then(response => response.json())
            .then(json => {
                if (json.users)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    disable(user_id) {
        let url = `/kuser/disable/id/${user_id}`;
        return this.POST(url)
            .then(response => response.json())
            .then(json => {
                if (json.user)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    enable(user_id) {
        let url = `/kuser/enable/id/${user_id}`;
        return this.POST(url)
            .then(response => response.json())
            .then(json => {
                if (json.user)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    hide(user_id) {
        let url = `/kuser/hide/id/${user_id}`;
        return this.POST(url)
            .then(response => response.json())
            .then(json => {
                if (json.user)
                    return json;
                throw new Error(json.flash.error);
            });
    }
}

const UsersApi = new Users();

export default UsersApi;
