import React, { useState }  from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Label,
    Input,
    ButtonGroup,
    Button,
    CardText,
    CardFooter,
} from 'reactstrap';
import { EmailLayout } from '../../../../../models';
import { TranslatableWysiwyg } from '../../../../../components';

import imagePlaceholder from '../../../../../assets/images/imagePlaceholder.png';

const EmailWidgetOptions = (props) => {
    const {
        widget, currentGroup, onSubmit, onGroupChange, onDelete,
        onDuplicate, onBackward, onForward, onClose,
        // the widgetRef is a ref to the widget main content and can
        // be set or not, depending on the widget type...
        widgetRef,
    } = props;

    const { isMobile } = useSelector(state => state.ui);

    const { t } = useTranslation();

    const [group, setGroup] = useState(currentGroup || "options");

    const setOption    = (option, value) => { widget.options[option] = value; saneSubmit(); }

    const saneSubmit = () => {
        // remove Widget instance prototype...
        onSubmit(widget.toPlainObject());
    }

    const showGroup = group => {
        setGroup(group);
        onGroupChange(group);
    }

    const selectImage = e => {
        const file = e.target.files[0];
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            setOption('rfc2397_data', fileReader.result);
        }
        fileReader.readAsDataURL(file)
    };

    const hasOptions = widget => {
        return (
            widget.handleOption(EmailLayout.OPTION_CONTACT_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_SCREENING_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_TICKET_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_TICKETTYPE_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_CUSTOMER_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_ENVIRONMENT_FIELD) ||
            widget.handleOption(EmailLayout.OPTION_IMAGE_TYPE) ||
            widget.handleOption(EmailLayout.OPTION_DATE_FORMAT) ||
            widget.handleOption(EmailLayout.OPTION_TEXT) ||
            widget.handleOption(EmailLayout.OPTION_LINK) ||
            widget.handleOption(EmailLayout.OPTION_IMAGE) ||
            widget.handleOption(EmailLayout.OPTION_OBJECT_FIT)
        );
    }

    return (
        <div className="wrapper-1 mr-1">
            <div className="">
                <CardText tag="div" className={"mb-4" + (isMobile ? " d-flex flex-column-reverse" : "")}>
                    <ButtonGroup>
                        <Button color={group === "options" ? 'primary' : 'dark'} onClick={() => showGroup("options")}> <i className="fa fa-cog" />{' '}
                            { t('layouts.widget_options.groups.options') }
                        </Button>
                    </ButtonGroup>
                    <Button type="button" color="danger" className="pull-right" onClick = { () => onClose() }>
                        <span>
                            <i className="fa fa-times"></i>
                        </span>
                    </Button>
                </CardText>

                { group === "options" && !hasOptions(widget) && (
                    <CardText tag="div" className="mb-3 text-center">
                        <b>
                            <i className="fa fa-info-circle"></i>&nbsp;
                            { t('layouts.no_options') }
                        </b>
                    </CardText>
                )}

                { group === "options" && hasOptions(widget) && (
                <CardText tag="div">
                    { widget.handleOption(EmailLayout.OPTION_IMAGE_TYPE) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.image_type")}</Label>
                            <Input defaultValue={widget.options?.type} type="select" onChange={ e => setOption('type', e.target.value) }>
                                <option>{t("layouts.image_type_placeholder")}</option>
                                {EmailLayout.imageTypes().map(type => (
                                    <option key={ type } value={ type }>
                                        {t(`common.image_types.${type}`)}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(EmailLayout.OPTION_TEXT) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.text")}</Label>
                            <TranslatableWysiwyg defaultValue={ widget.options?.text } onChange={ text => setOption('text', text) } fullToolbar />
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(EmailLayout.OPTION_IMAGE) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.click_to_choose_image")}</Label>
                            <div className="imageOption">
                                <img
                                    src={ widget.options?.rfc2397_data ? widget.options.rfc2397_data : imagePlaceholder }
                                />
                                <input className="file_input" type="file" accept="image/*" onChange={ e => selectImage(e) } />
                            </div>
                        </Col>
                    </Row>
                    )}
                </CardText>
                )}
                <CardFooter>
                    <Row>
                        <Col className={"text-" + (isMobile ? "center m-1": "left")}>
                            <ButtonGroup>
                                <Button type="button" color="danger" onClick = { () => onDelete() }>
                                    <span>
                                        <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col className={"text-" + (isMobile ? "center m-1": "right")}>
                            <ButtonGroup>
                                <Button type="button" color="info" onClick = { () => onDuplicate() }>
                                    <span>
                                        <i className="fa fa-copy"></i>&nbsp;{t("common.duplicate")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </CardFooter>
            </div>
        </div>
    );
}

export default EmailWidgetOptions;
